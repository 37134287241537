import { useState } from "react";
import { FcGoogle } from 'react-icons/fc';
import { FaGithub, FaTwitter } from 'react-icons/fa';
import { useAuth } from "../../contexts/auth";
import { useModal } from "../../contexts/ModalContext";
import { toast } from "react-hot-toast";
import { Sparkles } from "lucide-react";
import { Button } from '../common/Button';

interface SignInProps {
  onSuccess?: () => void;
}

export default function SignIn({ onSuccess }: SignInProps) {
  const { signInWithGoogle, signInWithGithub, signInWithTwitter } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { closeModal } = useModal();

  const handleSignIn = async (provider: 'google' | 'github' | 'twitter') => {
    if (isLoading) return;
    
    setIsLoading(true);
    try {
      switch (provider) {
        case 'google':
          await signInWithGoogle();
          break;
        case 'github':
          await signInWithGithub();
          break;
        case 'twitter':
          await signInWithTwitter();
          break;
      }
      toast.success("Successfully signed in!");
      onSuccess?.();
      closeModal();
    } catch (error: any) {
      console.error("Error signing in:", error);
      toast.error(error.message || "Failed to sign in");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div 
      className="p-6 space-y-6 relative overflow-hidden rounded-2xl"
      role="dialog"
      aria-labelledby="sign-in-title"
      aria-describedby="sign-in-description"
    >
      {/* Background layers */}
      <div className="absolute inset-0 bg-gradient-to-b from-[rgb(var(--background-start-rgb))] to-[rgb(var(--background-end-rgb))]" aria-hidden="true" />
      <div className="absolute inset-0" aria-hidden="true">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_left,rgba(249,188,96,0.1),transparent_40%),radial-gradient(circle_at_top_right,rgba(96,165,249,0.1),transparent_40%)]" />
        <div className="absolute inset-0 [background-image:url('data:image/svg+xml,%3Csvg%20width=\'60\'%20height=\'60\'%20viewBox=\'0%200%2060%2060\'%20xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg%20fill=\'none\'%20fill-rule=\'evenodd\'%3E%3Cg%20fill=\'%23ffffff\'%20fill-opacity=\'0.05\'%3E%3Cpath%20d=\'M36%2034v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6%2034v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6%204V0H4v4H0v2h4v4h2V6h4V4H6z\'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E')] opacity-15" />
      </div>
      
      <div className="relative">
        {/* Header */}
        <div className="text-center mb-8">
          <div 
            className="inline-flex items-center px-4 py-2 glass-effect rounded-full text-white mb-4 animate-scale-in"
            role="status"
            aria-live="polite"
          >
            <Sparkles className="w-4 h-4 mr-2 text-[rgb(var(--glow-rgb))]" aria-hidden="true" />
            <span className="text-sm font-medium">Welcome Back!</span>
          </div>
          <h2 
            id="modal-title"
            className="text-2xl font-bold text-white"
          >
            Sign In Now
          </h2>
          <p 
            id="sign-in-description"
            className="mt-2 text-zinc-200"
          >
            Choose your preferred sign-in method
          </p>
        </div>

        {/* Sign-in buttons */}
        <div 
          className="flex flex-col gap-4"
          role="group"
          aria-label="Sign-in options"
        >
          <Button
            onClick={() => handleSignIn('google')}
            isLoading={isLoading}
            leftIcon={<FcGoogle className="w-5 h-5" />}
            className="w-full"
          >
            Continue with Google
          </Button>
          <Button
            onClick={() => handleSignIn('github')}
            isLoading={isLoading}
            leftIcon={<FaGithub className="w-5 h-5" />}
            variant="secondary"
            className="w-full"
          >
            Continue with GitHub
          </Button>
          <Button
            onClick={() => handleSignIn('twitter')}
            isLoading={isLoading}
            leftIcon={<FaTwitter className="w-5 h-5" />}
            variant="outline"
            className="w-full"
          >
            Continue with Twitter
          </Button>
        </div>

        {/* Loading state announcement */}
        <div 
          className="sr-only" 
          role="status" 
          aria-live="polite"
        >
          {isLoading ? "Sign-in in progress..." : ""}
        </div>
      </div>
    </div>
  );
}
