import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { motion } from "framer-motion";

interface ProtectedRouteProps {
  requireOnboarding?: boolean;
  children?: React.ReactNode;
}

export function ProtectedRoute({ 
  requireOnboarding = false,
  children
}: ProtectedRouteProps) {
  const { user, loading, profile } = useAuth();
  const location = useLocation();

  // Show loading state
  if (loading) {
    return (
      <motion.div 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        className="flex items-center justify-center min-h-[50vh]"
      >
        <div className="flex flex-col items-center gap-4">
          <div className="w-8 h-8 rounded-full border-2 border-[rgb(var(--accent-1))] border-t-transparent animate-spin" />
          <p className="text-sm text-muted-foreground">Loading...</p>
        </div>
      </motion.div>
    );
  }

  // Redirect to sign in if not authenticated
  if (!user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // Check if onboarding is required and not completed
  if (requireOnboarding && (!profile || !profile.isProfileComplete)) {
    return <Navigate to="/onboarding" state={{ from: location }} replace />;
  }

  // All checks passed, render the protected content
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      {children || <Outlet />}
    </motion.div>
  );
}
