import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useMemo
} from 'react';
import {
  User,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
  createUserWithEmailAndPassword
} from 'firebase/auth';
import { auth, db } from '../lib/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import * as CryptoJS from 'crypto-js';
import { usersService } from '../services/FirestoreService';
import { UserProfile } from '../types/user';
import ProfileService from '../services/ProfileService';

// Define a type for the cached user data
interface CachedUserData {
  uid: string;
  displayName: string | null;
  photoURL: string | undefined;
  lastUpdated: number;
}

interface AuthContextType {
  user: User | null;
  profile: UserProfile | null;
  loading: boolean;
  error: string | null;
  showOnboarding: boolean;
  setShowOnboarding: (show: boolean) => void;
  signInWithGoogle: () => Promise<void>;
  signInWithGithub: () => Promise<void>;
  signInWithTwitter: () => Promise<void>;
  signUp: (email: string, password: string, displayName: string) => Promise<any>;
  logout: () => Promise<void>;
  refreshProfile: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

// Cache configuration
const USER_CACHE_KEY = 'launchpad_ui_state';
const CACHE_DURATION = 1000 * 60 * 30; // 30 minutes

// Secure cache helpers
const encryptData = (data: CachedUserData): string => {
  const secret = `cache-${data.uid}-${data.lastUpdated}`;
  return CryptoJS.AES.encrypt(JSON.stringify(data), secret).toString();
};

const decryptData = (encryptedData: string, uid: string, timestamp: number): any => {
  try {
    const secret = `cache-${uid}-${timestamp}`;
    const bytes = CryptoJS.AES.decrypt(encryptedData, secret);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.warn('Failed to decrypt cache data');
    return null;
  }
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(() => {
    try {
      // Try to get initial user state from cache
      const encryptedCache = localStorage.getItem(USER_CACHE_KEY);
      if (encryptedCache) {
        const cacheData = JSON.parse(localStorage.getItem(USER_CACHE_KEY + '-metadata') || '{}');
        const userData: CachedUserData = decryptData(encryptedCache, cacheData.uid, cacheData.lastUpdated);
        if (userData && Date.now() - userData.lastUpdated < CACHE_DURATION) {
          return {
            uid: userData.uid,
            displayName: userData.displayName,
            photoURL: userData.photoURL,
          } as User;
        }
      }
    } catch (error) {
      console.warn('Error reading from cache:', error);
    }
    return null;
  });
  
  const [profile, setProfile] = useState<UserProfile | null>(() => {
    try {
      const cachedProfile = sessionStorage.getItem('user_profile');
      return cachedProfile ? JSON.parse(cachedProfile) : null;
    } catch {
      return null;
    }
  });

  const [showOnboarding, setShowOnboarding] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Initialize auth persistence and listen for auth state changes
  useEffect(() => {
    setPersistence(auth, browserLocalPersistence).catch((error) => {
      console.error("Error setting auth persistence:", error);
    });

    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        cacheUserData(firebaseUser);
        await handleUser(firebaseUser);
      } else {
        setUser(null);
        setProfile(null);
        cacheUserData(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Cache the user data securely
  const cacheUserData = (user: User | null) => {
    try {
      if (user) {
        const userData: CachedUserData = {
          uid: user.uid,
          displayName: user.displayName,
          photoURL: user.photoURL || undefined,
          lastUpdated: Date.now(),
        };
        const encryptedData = encryptData(userData);
        localStorage.setItem(USER_CACHE_KEY, encryptedData);
        localStorage.setItem(USER_CACHE_KEY + '-metadata', JSON.stringify({ uid: userData.uid, lastUpdated: userData.lastUpdated }));
      } else {
        localStorage.removeItem(USER_CACHE_KEY);
        localStorage.removeItem(USER_CACHE_KEY + '-metadata');
        sessionStorage.removeItem('user_profile');
      }
    } catch (error) {
      console.warn('Error writing to cache:', error);
    }
  };

  const refreshProfile = async () => {
    if (user) {
      try {
        const userProfile = await usersService.get(user.uid);
        // Create a valid UserProfile object from available data
        setProfile(userProfile ? {
          uid: user.uid,
          email: userProfile.email || '',
          displayName: userProfile.name || '',
          photoURL: userProfile.photoURL,
          skills: [],
          interests: [],
          links: {
            github: userProfile.github,
            twitter: userProfile.twitter,
            linkedin: userProfile.linkedin,
            website: userProfile.websiteUrl
          },
          isProfileComplete: Boolean(userProfile.isProfileComplete),
          createdAt: userProfile.createdAt || new Date().toISOString(),
          updatedAt: userProfile.updatedAt || new Date().toISOString()
        } : null);
        
        // Show onboarding if profile is incomplete
        if (userProfile && !userProfile.isProfileComplete) {
          setShowOnboarding(true);
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    } else {
      setProfile(null);
      sessionStorage.removeItem('user_profile');
    }
  };

  // Handle user profile creation/update
  const handleUser = async (user: User | null) => {
    if (!user) {
      setUser(null);
      cacheUserData(null);
      setLoading(false);
      return;
    }

    try {
      // Set the user state and cache immediately
      setUser(user);
      cacheUserData(user);

      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        // Create new user document with initial data
        const initialProfile: UserProfile = {
          uid: user.uid,
          email: user.email || '',
          displayName: user.displayName || '',
          photoURL: user.photoURL || undefined,
          skills: [],
          interests: [],
          links: {},
          isProfileComplete: false,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };

        await setDoc(userRef, initialProfile);
        setShowOnboarding(true);
      }
      
      // Fetch profile after user is set
      await refreshProfile();
    } catch (error) {
      console.error('Error handling user:', error);
      setError('Error setting up user profile');
    } finally {
      setLoading(false);
    }
  };

  const handleAuthSuccess = async (userCredential: any) => {
    try {
      const { user } = userCredential;
      
      // Check if profile exists
      const profile = await ProfileService.getInstance().getProfile(user.uid);
      
      // If no profile exists, create one
      if (!profile) {
        await ProfileService.getInstance().createProfile(user.uid, {
          displayName: user.displayName || 'Anonymous',
          photoURL: user.photoURL || '',
          bio: '',
          skills: [],
          interests: [],
          links: {} as UserProfile['links'],
        });
      }
    } catch (error) {
      console.error('Error handling auth success:', error);
      throw error;
    }
  };

  const signUp = async (email: string, password: string, displayName: string) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Create user profile
      await ProfileService.getInstance().createProfile(userCredential.user.uid, {
        displayName,
        photoURL: userCredential.user.photoURL || '',
        bio: '',
        skills: [],
        interests: [],
        links: {},
      });

      return userCredential;
    } catch (error) {
      console.error('Error signing up:', error);
      throw error;
    }
  };

  const signInWithGoogle = async () => {
    try {
      setError(null);
      setLoading(true);
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      await handleAuthSuccess(result);
      await handleUser(result.user);
      window.location.reload(); // Force reload after sign in
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError('Error signing in with Google');
      setLoading(false);
    }
  };

  const signInWithGithub = async () => {
    try {
      setError(null);
      setLoading(true);
      const provider = new GithubAuthProvider();
      const result = await signInWithPopup(auth, provider);
      await handleAuthSuccess(result);
      await handleUser(result.user);
      window.location.reload(); // Force reload after sign in
    } catch (error) {
      console.error('Error signing in with Github:', error);
      setError('Error signing in with Github');
      setLoading(false);
    }
  };

  const signInWithTwitter = async () => {
    try {
      setError(null);
      setLoading(true);
      const provider = new TwitterAuthProvider();
      const result = await signInWithPopup(auth, provider);
      await handleAuthSuccess(result);
      await handleUser(result.user);
      window.location.reload(); // Force reload after sign in
    } catch (error) {
      console.error('Error signing in with Twitter:', error);
      setError('Error signing in with Twitter');
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      cacheUserData(null);
      // Clear all auth-related items from localStorage
      localStorage.removeItem(USER_CACHE_KEY);
      localStorage.removeItem(USER_CACHE_KEY + '-metadata');
      localStorage.removeItem('redirectAfterLogin');
      setProfile(null);
    } catch (error) {
      console.error('Error signing out:', error);
      setError('Error signing out');
    }
  };

  // Listen to auth state changes
  useEffect(() => {
    let mounted = true;
    
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (!mounted) return;
      
      if (firebaseUser) {
        await handleUser(firebaseUser);
      } else {
        setUser(null);
        cacheUserData(null);
        setProfile(null);
        setLoading(false);
      }
    });

    return () => {
      mounted = false;
      unsubscribe();
    };
  }, []);

  // Memoize the context value to prevent unnecessary re-renders
  const value = useMemo(
    () => ({
      user,
      profile,
      loading,
      error,
      showOnboarding,
      setShowOnboarding,
      signInWithGoogle,
      signInWithGithub,
      signInWithTwitter,
      signUp,
      logout,
      refreshProfile
    }),
    [user, profile, loading, error, showOnboarding]
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

// Custom hook to use auth context
export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

const AuthContext = createContext<AuthContextType | null>(null);
