import React from 'react';

interface FormFieldProps {
  label: string;
  name: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  required?: boolean;
  placeholder?: string;
  error?: string;
  as?: 'input' | 'textarea';
  className?: string;
}

export function FormField({
  label,
  name,
  type,
  value,
  onChange,
  required = false,
  placeholder,
  error,
  as = 'input',
  className = '',
}: FormFieldProps) {
  const inputClasses = `w-full px-3 py-2 border rounded-lg bg-background ${
    error ? 'border-red-500' : 'border-border'
  } focus:outline-none focus:ring-2 focus:ring-[rgb(var(--accent-1))] ${className}`;

  const Component = as;

  return (
    <div className="mb-4">
      <label htmlFor={name} className="block text-sm font-medium mb-1">
        {label}
        {required && <span className="text-red-500 ml-1">*</span>}
      </label>
      <Component
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        className={inputClasses}
      />
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
}
