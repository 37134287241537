import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, X, Loader2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useDebounce } from '../hooks/useDebounce';
import SearchService, { SearchResult } from '../services/SearchService';
import { Input } from './common/Input';
import { Avatar } from './common/Avatar';
import { Badge } from './common/Badge';

interface GlobalSearchProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function GlobalSearch({ isOpen, onClose }: GlobalSearchProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    const performSearch = async () => {
      if (!debouncedSearchTerm) {
        setResults([]);
        return;
      }

      setLoading(true);
      try {
        const searchResults = await SearchService.getInstance().globalSearch(debouncedSearchTerm);
        setResults(searchResults);
      } catch (error) {
        console.error('Search error:', error);
      } finally {
        setLoading(false);
      }
    };

    performSearch();
  }, [debouncedSearchTerm]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => (prev + 1) % results.length);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => (prev - 1 + results.length) % results.length);
        break;
      case 'Enter':
        if (results[selectedIndex]) {
          handleSelect(results[selectedIndex]);
        }
        break;
      case 'Escape':
        onClose();
        break;
    }
  };

  const handleSelect = (result: SearchResult) => {
    switch (result.type) {
      case 'project':
        navigate(`/projects/${result.id}`);
        break;
      case 'event':
        navigate(`/events/${result.id}`);
        break;
      case 'collaboration':
        navigate(`/collaboration/${result.id}`);
        break;
      case 'user':
        navigate(`/${result.id}`);
        break;
    }
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="min-h-screen px-4 text-center">
        <div className="fixed inset-0 bg-background/80 backdrop-blur-sm" onClick={onClose} />
        
        <div className="inline-block w-full max-w-2xl my-16 text-left align-middle transition-all transform">
          <div className="relative bg-card rounded-lg shadow-xl border">
            <div className="flex items-center p-4 border-b">
              <Search className="w-5 h-5 text-muted-foreground mr-2" />
              <Input
                ref={inputRef}
                type="text"
                placeholder="Search everything..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
                className="flex-1 border-none shadow-none focus-visible:ring-0"
              />
              {loading && <Loader2 className="w-5 h-5 animate-spin text-muted-foreground ml-2" />}
              <button
                onClick={onClose}
                className="ml-2 p-1 rounded-full hover:bg-muted"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="max-h-[60vh] overflow-y-auto p-2">
              {/* Initial state */}
              {!searchTerm && (
                <div className="p-8 text-center text-muted-foreground">
                  <Search className="w-12 h-12 mx-auto mb-4 opacity-50" />
                  <p className="text-lg mb-2">Search across your workspace</p>
                  <p className="text-sm">Find projects, events, collaborations, and users</p>
                </div>
              )}

              {/* No results state */}
              {searchTerm && results.length === 0 && !loading && (
                <div className="p-8 text-center text-muted-foreground">
                  <p className="text-lg mb-2">No results found</p>
                  <p className="text-sm">Try different keywords or check your spelling</p>
                </div>
              )}

              {/* Loading state */}
              {loading && (
                <div className="p-8 text-center text-muted-foreground">
                  <Loader2 className="w-8 h-8 mx-auto mb-4 animate-spin" />
                  <p>Searching...</p>
                </div>
              )}
              <AnimatePresence>
                {results.map((result, index) => (
                  <motion.div
                    key={`${result.type}-${result.id}`}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.15 }}
                    className={`p-3 rounded-lg cursor-pointer ${
                      selectedIndex === index ? 'bg-muted' : 'hover:bg-muted'
                    }`}
                    onClick={() => handleSelect(result)}
                  >
                    <div className="flex items-start gap-3">
                      {result.imageUrl ? (
                        <img
                          src={result.imageUrl}
                          alt={result.title}
                          className="w-12 h-12 rounded-lg object-cover"
                        />
                      ) : (
                        <div className="w-12 h-12 rounded-lg bg-muted flex items-center justify-center">
                          <Search className="w-6 h-6 text-muted-foreground" />
                        </div>
                      )}
                      <div className="flex-1 min-w-0">
                        <div className="flex items-center gap-2">
                          <Badge variant="outline">{result.type}</Badge>
                          <h4 className="text-sm font-medium truncate">{result.title}</h4>
                        </div>
                        <p className="text-sm text-muted-foreground line-clamp-2 mt-1">
                          {result.description}
                        </p>
                        {result.tags.length > 0 && (
                          <div className="flex flex-wrap gap-1 mt-2">
                            {result.tags.map(tag => (
                              <Badge key={tag} variant="secondary" className="text-xs">
                                {tag}
                              </Badge>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="flex items-center gap-2">
                        <Avatar
                          src={result.creator.avatar}
                          alt={result.creator.name}
                          className="w-6 h-6"
                        />
                        <span className="text-xs text-muted-foreground">
                          {result.creator.name}
                        </span>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
