import { db } from '../lib/firebase';
import { doc, getDoc, setDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import { UserProfile } from '../types/user';

class ProfileService {
  private static instance: ProfileService;

  private constructor() {}

  public static getInstance(): ProfileService {
    if (!ProfileService.instance) {
      ProfileService.instance = new ProfileService();
    }
    return ProfileService.instance;
  }

  async getProfile(userId: string): Promise<UserProfile | null> {
    try {
      const profileDoc = await getDoc(doc(db, 'profiles', userId));
      if (profileDoc.exists()) {
        return { uid: profileDoc.id, ...profileDoc.data() } as UserProfile;
      }
      return null;
    } catch (error) {
      console.error('Error fetching profile:', error);
      return null;
    }
  }

  async getProfileByUsername(username: string): Promise<UserProfile[]> {
    try {
      const q = query(
        collection(db, 'profiles'),
        where('username', '==', username)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        uid: doc.id,
        ...doc.data()
      })) as UserProfile[];
    } catch (error) {
      console.error('Error fetching profile by username:', error);
      return [];
    }
  }

  private async generateUniqueUsername(baseUsername: string): Promise<string> {
    // Remove special characters and spaces, convert to lowercase
    let username = baseUsername.toLowerCase().replace(/[^a-z0-9]/g, '');
    
    // Check if username exists
    const usernameQuery = query(collection(db, 'profiles'), where('username', '==', username));
    const existingUsernames = await getDocs(usernameQuery);
    
    if (existingUsernames.empty) {
      return username;
    }
    
    // If username exists, append a number
    let counter = 1;
    let newUsername = username;
    
    while (true) {
      newUsername = `${username}${counter}`;
      const newUsernameQuery = query(collection(db, 'profiles'), where('username', '==', newUsername));
      const existingNewUsernames = await getDocs(newUsernameQuery);
      
      if (existingNewUsernames.empty) {
        return newUsername;
      }
      
      counter++;
    }
  }

  async validateUsername(username: string): Promise<boolean> {
    // Check if username matches required format
    if (!/^[a-z0-9]{3,30}$/.test(username)) {
      return false;
    }
    
    // Check if username is available
    const usernameQuery = query(collection(db, 'profiles'), where('username', '==', username));
    const existingUsernames = await getDocs(usernameQuery);
    
    return existingUsernames.empty;
  }

  async createProfile(userId: string, data: Partial<UserProfile>): Promise<void> {
    try {
      const now = new Date().toISOString();
      
      // Get the user's Firebase Auth profile if available
      const auth = getAuth();
      const firebaseUser = auth.currentUser;
      
      // Generate a unique username based on display name or fallback
      const baseUsername = data.displayName || firebaseUser?.displayName || `user${userId.slice(0, 6)}`;
      const username = await this.generateUniqueUsername(baseUsername);
      
      const profileData: UserProfile = {
        uid: userId,
        email: firebaseUser?.email || '',
        username,
        displayName: data.displayName || firebaseUser?.displayName || username,
        photoURL: data.photoURL || firebaseUser?.photoURL || undefined,
        bio: data.bio || '',
        title: data.title || '',
        company: data.company || '',
        location: data.location || '',
        website: data.website || '',
        githubUsername: data.githubUsername || '',
        twitterHandle: data.twitterHandle || '',
        skills: data.skills || [],
        experience: data.experience || '',
        education: data.education || '',
        projects: data.projects || [],
        interests: data.interests || [],
        isProfileComplete: false,
        createdAt: now,
        updatedAt: now
      };
      
      await setDoc(doc(db, 'profiles', userId), profileData);
    } catch (error) {
      console.error('Error creating profile:', error);
      throw error;
    }
  }

  async updateProfile(userId: string, data: Partial<UserProfile>): Promise<void> {
    try {
      const updates = {
        ...data,
        updatedAt: new Date().toISOString(),
      };
      await updateDoc(doc(db, 'profiles', userId), updates);
    } catch (error) {
      console.error('Error updating profile:', error);
      throw error;
    }
  }
}

export default ProfileService;
