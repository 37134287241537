// React is used implicitly for JSX

export function StructuredData() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Launchpad",
    "description": "A platform for developers to showcase their projects and connect with others",
    "url": window.location.origin
  };

  return (
    <script type="application/ld+json">
      {JSON.stringify(structuredData)}
    </script>
  );
}
