interface AvatarProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
  alt: string;
  className?: string;
}

export function Avatar({ src, alt, className = '', ...props }: AvatarProps) {
  // Generate a deterministic seed from the alt text
  const seed = alt.toLowerCase().replace(/[^a-z0-9]/g, '');
  
  // Use bottts style for fun, unique avatars
  const fallbackUrl = `https://api.dicebear.com/7.x/bottts/svg?seed=${seed}&backgroundColor=b6e3f4`;

  return (
    <img
      src={src || fallbackUrl}
      alt={alt}
      className={`rounded-full object-cover ${className}`}
      onError={(e) => {
        const target = e.target as HTMLImageElement;
        target.src = fallbackUrl;
      }}
      {...props}
    />
  );
}
