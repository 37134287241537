import localforage from 'localforage';
import { NetworkStatus } from '../types/network';

interface CacheConfig {
  maxAge: number; // milliseconds
  version: string;
}

interface CacheEntry<T> {
  data: T;
  timestamp: number;
  version: string;
}

class CacheService {
  private static instance: CacheService;
  private networkStatus: NetworkStatus = 'online';
  private cacheConfig: CacheConfig = {
    maxAge: 1000 * 60 * 60, // 1 hour default
    version: '1.0.0',
  };

  private constructor() {
    this.initializeNetworkListener();
  }

  public static getInstance(): CacheService {
    if (!CacheService.instance) {
      CacheService.instance = new CacheService();
    }
    return CacheService.instance;
  }

  private initializeNetworkListener(): void {
    window.addEventListener('online', () => {
      this.networkStatus = 'online';
      this.processPendingOperations();
    });

    window.addEventListener('offline', () => {
      this.networkStatus = 'offline';
    });
  }

  public async setItem<T>(key: string, data: T, config?: Partial<CacheConfig>): Promise<void> {
    const entry: CacheEntry<T> = {
      data,
      timestamp: Date.now(),
      version: config?.version || this.cacheConfig.version,
    };

    try {
      await localforage.setItem(key, entry);
    } catch (error) {
      console.error('Error caching data:', error);
    }
  }

  public async getItem<T>(key: string, fetchFn?: () => Promise<T>): Promise<T | null> {
    try {
      const entry = await localforage.getItem<CacheEntry<T>>(key);

      // If no cache entry and we're online, fetch fresh data
      if (!entry && this.networkStatus === 'online' && fetchFn) {
        const freshData = await fetchFn();
        await this.setItem(key, freshData);
        return freshData;
      }

      // If cache entry exists but is expired and we're online, fetch fresh data
      if (entry && this.isCacheExpired(entry) && this.networkStatus === 'online' && fetchFn) {
        const freshData = await fetchFn();
        await this.setItem(key, freshData);
        return freshData;
      }

      // Return cached data if it exists
      return entry ? entry.data : null;
    } catch (error) {
      console.error('Error retrieving cached data:', error);
      return null;
    }
  }

  private isCacheExpired(entry: CacheEntry<any>): boolean {
    const age = Date.now() - entry.timestamp;
    return age > this.cacheConfig.maxAge || entry.version !== this.cacheConfig.version;
  }

  // Offline Support
  // This function is currently unused but kept for future implementation
  // Reserved for future implementation
  /* 
  private async _queueOperation(_operation: {
    type: 'create' | 'update' | 'delete';
    collection: string;
    data: any;
    id?: string;
  }): Promise<void> {
    const pendingOps = await this.getPendingOperations();
    pendingOps.push({
      ..._operation,
      timestamp: Date.now(),
    });
    await localforage.setItem('pendingOperations', pendingOps);
  }
  */

  private async getPendingOperations(): Promise<any[]> {
    return (await localforage.getItem<any[]>('pendingOperations')) || [];
  }

  private async processPendingOperations(): Promise<void> {
    if (this.networkStatus === 'offline') return;

    const pendingOps = await this.getPendingOperations();
    if (!pendingOps.length) return;

    for (const op of pendingOps) {
      try {
        // Process operation based on type
        switch (op.type) {
          case 'create':
            // Handle create operation
            break;
          case 'update':
            // Handle update operation
            break;
          case 'delete':
            // Handle delete operation
            break;
        }
        
        // Remove processed operation
        const remainingOps = await this.getPendingOperations();
        const filteredOps = remainingOps.filter(
          (pendingOp) => pendingOp.timestamp !== op.timestamp
        );
        await localforage.setItem('pendingOperations', filteredOps);
      } catch (error) {
        console.error('Error processing pending operation:', error);
      }
    }
  }

  // Data Prefetching
  public async prefetch(keys: string[], fetchFns: { [key: string]: () => Promise<any> }): Promise<void> {
    if (this.networkStatus === 'offline') return;

    const prefetchPromises = keys.map(async (key) => {
      const fetchFn = fetchFns[key];
      if (!fetchFn) return;

      try {
        const data = await fetchFn();
        await this.setItem(key, data);
      } catch (error) {
        console.error(`Error prefetching ${key}:`, error);
      }
    });

    await Promise.all(prefetchPromises);
  }

  // Cache Management
  public async clearCache(): Promise<void> {
    await localforage.clear();
  }

  public async removeItem(key: string): Promise<void> {
    await localforage.removeItem(key);
  }

  public getNetworkStatus(): NetworkStatus {
    return this.networkStatus;
  }

  public setConfig(config: Partial<CacheConfig>): void {
    this.cacheConfig = { ...this.cacheConfig, ...config };
  }
}

export default CacheService;
