import { useState, useEffect } from "react";
import { Bell, X } from "lucide-react";

interface Notification {
  id: string;
  type: "info" | "success" | "warning" | "error";
  message: string;
  timestamp: string;
  read: boolean;
}

function Notifications() {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const unreadCount = notifications.filter((n) => !n.read).length;

  useEffect(() => {
    // Load stored notifications or set default
    const storedNotifications = localStorage.getItem("notifications");
    if (storedNotifications) {
      setNotifications(JSON.parse(storedNotifications));
    } else {
      // Add default welcome notification
      const defaultNotification: Notification = {
        id: "welcome",
        type: "info",
        message: "Welcome to Launchpad! Start exploring the community, glad to have you here.",
        timestamp: new Date().toISOString(),
        read: false
      };
      setNotifications([defaultNotification]);
      localStorage.setItem("notifications", JSON.stringify([defaultNotification]));
    }
  }, []);

  const markAsRead = (id: string) => {
    const updatedNotifications = notifications.map((notification) =>
      notification.id === id ? { ...notification, read: true } : notification
    );
    setNotifications(updatedNotifications);
    localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
  };

  const markAllAsRead = () => {
    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      read: true,
    }));
    setNotifications(updatedNotifications);
    localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
  };

  const deleteNotification = (id: string) => {
    const updatedNotifications = notifications.filter(
      (notification) => notification.id !== id
    );
    setNotifications(updatedNotifications);
    localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
  };

  const getNotificationColor = (type: Notification["type"]) => {
    switch (type) {
      case "success":
        return "text-orange-500";
      case "error":
        return "text-red-500";
      case "warning":
        return "text-yellow-500";
      default:
        return "text-[rgb(var(--glow-rgb))]";
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative p-2 hover:bg-zinc-800/50 rounded-xl transition-colors"
        aria-label="Toggle notifications"
      >
        <Bell size={20} />
        {unreadCount > 0 && (
          <span className="absolute -top-1 -right-1 bg-[rgb(var(--glow-rgb))] text-black text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
            {unreadCount}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-80 bg-zinc-900/90 backdrop-blur-xl border border-zinc-800/50 rounded-xl shadow-xl z-50">
          <div className="flex items-center justify-between p-4 border-b border-zinc-800/50">
            <h2 className="text-lg font-semibold">Notifications</h2>
            {notifications.length > 0 && (
              <button
                onClick={markAllAsRead}
                className="text-sm text-[rgb(var(--glow-rgb))] hover:text-orange-500 transition-colors"
              >
                Mark all as read
              </button>
            )}
          </div>

          <div className="max-h-96 overflow-y-auto">
            {notifications.length === 0 ? (
              <div className="p-4 text-center text-zinc-400">
                No notifications
              </div>
            ) : (
              notifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`p-4 border-b border-zinc-800/50 hover:bg-zinc-800/30 transition-colors ${
                    !notification.read ? "bg-zinc-800/10" : ""
                  }`}
                >
                  <div className="flex justify-between items-start">
                    <div
                      className={`flex-1 ${
                        !notification.read ? "font-medium" : ""
                      }`}
                    >
                      <p className={getNotificationColor(notification.type)}>
                        {notification.message}
                      </p>
                      <p className="text-xs text-zinc-400 mt-1">
                        {new Date(notification.timestamp).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      {!notification.read && (
                        <button
                          onClick={() => markAsRead(notification.id)}
                          className="text-xs text-[rgb(var(--glow-rgb))] hover:text-orange-500 transition-colors"
                        >
                          Mark as read
                        </button>
                      )}
                      <button
                        onClick={() => deleteNotification(notification.id)}
                        className="text-zinc-400 hover:text-zinc-300 transition-colors"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Notifications;
