import { useState, useEffect } from 'react';
import { MessageCircle } from 'lucide-react';
import { useAuth } from '../../contexts/auth';
import RealtimeService, { ChatThread } from '../../services/RealtimeService';
import { Button } from '../common/Button';

interface ChatThreadListProps {
  onSelectThread?: (thread: ChatThread) => void;
}

export default function ChatThreadList({ onSelectThread }: ChatThreadListProps) {
  const [threads, setThreads] = useState<ChatThread[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const realtimeService = RealtimeService.getInstance();

  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }

    setLoading(true);
    let isSubscribed = true;

    try {
      // Subscribe to real-time updates for chat threads
      const unsubscribe = realtimeService.subscribeToUserThreads(user.uid, (updatedThreads) => {
        if (!isSubscribed) return;

        if (Array.isArray(updatedThreads)) {
          // Filter out any invalid threads
          const validThreads = updatedThreads.filter(thread => {
            if (!thread || !thread.id || !thread.title || !Array.isArray(thread.participants)) {
              console.error('Invalid thread data:', thread);
              return false;
            }
            return true;
          });

          // Sort threads by most recent first
          const sortedThreads = validThreads.sort((a, b) => (b.updatedAt || 0) - (a.updatedAt || 0));
          setThreads(sortedThreads);
        } else {
          console.error('Updated threads is not an array:', updatedThreads);
          setThreads([]);
        }
        setLoading(false);
      });

      // Cleanup subscription on unmount
      return () => {
        isSubscribed = false;
        unsubscribe();
      };
    } catch (error) {
      console.error('Error setting up thread subscription:', error);
      if (isSubscribed) {
        setLoading(false);
        setThreads([]);
      }
      return () => {
        isSubscribed = false;
      };
    }
  }, [user]);

  if (loading) {
    return (
      <div className="flex-1 overflow-y-auto min-h-0">
        <div className="p-4">
          <div className="animate-pulse space-y-4">
            <div className="h-12 bg-muted rounded-lg"></div>
            <div className="h-12 bg-muted rounded-lg"></div>
            <div className="h-12 bg-muted rounded-lg"></div>
          </div>
        </div>
      </div>
    );
  }

  if (threads.length === 0) {
    return (
      <div className="flex-1 overflow-y-auto min-h-0">
        <div className="h-full flex items-center justify-center">
          <div className="text-center text-muted-foreground p-4">
            <MessageCircle className="mx-auto h-8 w-8 mb-2" />
            <p>No chat threads yet</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 overflow-y-auto min-h-0">
      <div className="space-y-2 p-4">
        {threads.map((thread) => (
          <Button
            key={thread.id}
            variant="ghost"
            className="w-full justify-start text-left"
            onClick={() => onSelectThread?.(thread)}
          >
            <div className="flex items-center space-x-3">
              <MessageCircle className="h-5 w-5" />
              <div className="flex-1 min-w-0">
                <p className="truncate font-medium">{thread.title}</p>
                {thread.lastMessage && (
                  <p className="text-sm text-muted-foreground truncate">
                    {thread.lastMessage.content}
                  </p>
                )}
              </div>
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
}
