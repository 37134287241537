import { useCallback } from 'react';
import toast from 'react-hot-toast';
import NotificationService from '../services/NotificationService';

type NotificationType = 'info' | 'success' | 'warning' | 'error';

interface NotificationOptions {
  type?: NotificationType;
  persistent?: boolean;
  toast?: boolean;
  timeout?: number;
}

const defaultOptions: NotificationOptions = {
  type: 'info',
  persistent: false,
  toast: true,
  timeout: 5000,
};

export function useNotification() {
  const notifyService = NotificationService.getInstance();

  const notify = useCallback((message: string, options: NotificationOptions = {}) => {
    const { type, persistent, toast: showToast, timeout } = {
      ...defaultOptions,
      ...options,
    };

    // Show toast notification if enabled
    if (showToast) {
      switch (type) {
        case 'success':
          toast.success(message, { duration: timeout });
          break;
        case 'error':
          toast.error(message, { duration: timeout });
          break;
        case 'warning':
          toast(message, {
            duration: timeout,
            icon: '⚠️',
          });
          break;
        default:
          if (type === undefined) {
            console.error('Notification type is undefined');
          } else {
            toast(message, { duration: timeout });
          }
      }
    }

    // Add to persistent notifications if enabled
    if (persistent && type !== undefined) {
      notifyService.addNotification(message, type);
    } else if (persistent && type === undefined) {
      console.error('Cannot add notification with undefined type');
    }
  }, []);

  const success = useCallback(
    (message: string, options?: Omit<NotificationOptions, 'type'>) => {
      notify(message, { ...options, type: 'success' });
    },
    [notify]
  );

  const error = useCallback(
    (message: string, options?: Omit<NotificationOptions, 'type'>) => {
      notify(message, { ...options, type: 'error' });
    },
    [notify]
  );

  const warning = useCallback(
    (message: string, options?: Omit<NotificationOptions, 'type'>) => {
      notify(message, { ...options, type: 'warning' });
    },
    [notify]
  );

  const info = useCallback(
    (message: string, options?: Omit<NotificationOptions, 'type'>) => {
      notify(message, { ...options, type: 'info' });
    },
    [notify]
  );

  return {
    notify,
    success,
    error,
    warning,
    info,
  };
}
