import { motion } from "framer-motion";
import { Loader2 } from "lucide-react";

interface LoadingProps {
  size?: "sm" | "md" | "lg";
  text?: string;
}

export function Loading({ size = "md", text = "Loading..." }: LoadingProps) {
  const sizeClasses = {
    sm: "w-4 h-4",
    md: "w-8 h-8",
    lg: "w-12 h-12"
  };

  return (
    <div className="flex items-center justify-center min-h-[200px]">
      <motion.div
        className="flex flex-col items-center space-y-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <Loader2 className={`${sizeClasses[size]} animate-spin text-[rgb(var(--glow-rgb))]`} />
        {text && <p className="text-zinc-400 text-sm">{text}</p>}
      </motion.div>
    </div>
  );
}

export default Loading;
