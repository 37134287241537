import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { usersService } from '../../services/FirestoreService';
import { useNotification } from '../../hooks/useNotification';
import { Modal } from '../common/Modal';
import { Card, CardContent, CardFooter } from '../common/Card';
import { Button } from '../common/Button';
import { FormField } from '../common/FormField';
import { TagInput } from '../common/TagInput';
import { UserProfile } from '../../types/documents';

interface OnboardingModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialData?: {
    uid: string;
    email: string;
    displayName: string;
    photoURL?: string;
  };
}

export function OnboardingModal({ isOpen, onClose, initialData }: OnboardingModalProps) {
  const navigate = useNavigate();
  const { user, refreshProfile } = useAuth();
  const { success, error: showError } = useNotification();

  const [formData, setFormData] = useState({
    displayName: initialData?.displayName || '',
    bio: '',
    location: '',
    skills: [] as string[],
    interests: [] as string[],
    links: {
      github: '',
      twitter: '',
      linkedin: '',
      website: '',
    },
  });

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleChange = (field: string, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      const userData: Partial<UserProfile> = {
        ...formData,
        ...initialData,
        isProfileComplete: true,
        updatedAt: new Date().toISOString(),
      };

      await usersService.update(user.uid, userData);
      await refreshProfile();
      success('Profile updated successfully');
      onClose();
      navigate('/dashboard');
      window.location.reload(); // Refresh the app to update all components
    } catch (err) {
      console.error('Error updating profile:', err);
      showError('Failed to update profile');
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Complete Your Profile">
      <form onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <div className="space-y-4">
              <p className="text-muted-foreground mb-6">
                Welcome to Launchpad! Let's get your profile set up so you can start
                collaborating with others.
              </p>

              <FormField
                label="Display Name"
                name="displayName"
                type="text"
                value={formData.displayName}
                onChange={(e) => handleChange('displayName', e.target.value)}
                required
              />

              <FormField
                label="Bio"
                name="bio"
                type="textarea"
                value={formData.bio}
                onChange={(e) => handleChange('bio', e.target.value)}
                placeholder="Tell us about yourself..."
              />

              <FormField
                label="Location"
                name="location"
                type="text"
                value={formData.location}
                onChange={(e) => handleChange('location', e.target.value)}
                placeholder="City, Country"
              />

              <div className="space-y-2">
                <label className="block text-sm font-medium">Skills</label>
                <TagInput
                  value={formData.skills}
                  onChange={(tags) => handleChange('skills', tags)}
                  placeholder="Add skills..."
                />
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium">Interests</label>
                <TagInput
                  value={formData.interests}
                  onChange={(tags) => handleChange('interests', tags)}
                  placeholder="Add interests..."
                />
              </div>
            </div>
          </CardContent>
          <CardFooter>
            <div className="flex justify-end space-x-2">
              <Button type="button" variant="outline" onClick={onClose}>
                Skip for now
              </Button>
              <Button type="submit">
                Continue
              </Button>
            </div>
          </CardFooter>
        </Card>
      </form>
    </Modal>
  );
}
