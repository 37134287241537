import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { ChatThread } from '../services/RealtimeService';
import RealtimeService from '../services/RealtimeService';
import { useAuth } from './auth';

interface ChatContextType {
  showChats: boolean;
  setShowChats: (show: boolean) => void;
  selectedThread: ChatThread | null;
  setSelectedThread: (thread: ChatThread | null) => void;
  unreadCount: number;
  setUnreadCount: (count: number) => void;
  threads: ChatThread[];
  setThreads: (threads: ChatThread[]) => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export function ChatProvider({ children }: { children: ReactNode }) {
  const [showChats, setShowChats] = useState(false);
  const [selectedThread, setSelectedThread] = useState<ChatThread | null>(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [threads, setThreads] = useState<ChatThread[]>([]);
  const { user } = useAuth();
  const realtimeService = RealtimeService.getInstance();

  // Set current user ID in RealtimeService
  useEffect(() => {
    realtimeService.setCurrentUserId(user?.uid || null);
  }, [user]);

  // Subscribe to user's threads and track unread messages
  useEffect(() => {
    if (!user) return;

    const unsubscribe = realtimeService.subscribeToUserThreads(user.uid, (updatedThreads) => {
      setThreads(updatedThreads);
      
      // Calculate total unread messages
      const totalUnread = updatedThreads.reduce((count, thread) => {
        // Only count messages that:
        // 1. Are not read
        // 2. Were sent by someone else
        // 3. Thread is not currently selected and visible
        const unreadMessages = thread.messages?.filter(msg => 
          !msg.read && 
          msg.sender.id !== user.uid && 
          (!selectedThread || selectedThread.id !== thread.id || showChats === false)
        ) || [];
        return count + unreadMessages.length;
      }, 0);

      setUnreadCount(totalUnread);
    });

    return () => unsubscribe();
  }, [user, selectedThread, showChats]);

  return (
    <ChatContext.Provider value={{
      showChats,
      setShowChats,
      selectedThread,
      setSelectedThread,
      unreadCount,
      setUnreadCount,
      threads,
      setThreads,
    }}>
      {children}
    </ChatContext.Provider>
  );
}

export function useChat() {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}
