import { ReactNode } from 'react';

interface CardProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  hoverable?: boolean;
  interactive?: boolean;
  variant?: 'default' | 'glass';
}

export function Card({
  children,
  className = '',
  onClick,
  hoverable = false,
  interactive = false,
  variant = 'default',
}: CardProps) {
  const baseStyles = 'rounded-lg border border-border overflow-hidden';
  const variantStyles = {
    default: 'bg-card',
    glass: 'glass-effect backdrop-blur-md',
  };
  const interactiveStyles = interactive
    ? 'cursor-pointer transition-transform active:scale-[0.98]'
    : '';
  const hoverStyles = hoverable ? 'hover:shadow-lg transition-shadow' : '';

  return (
    <div
      className={`${baseStyles} ${variantStyles[variant]} ${interactiveStyles} ${hoverStyles} ${className}`}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      tabIndex={onClick ? 0 : undefined}
    >
      {children}
    </div>
  );
}

interface CardHeaderProps {
  children: ReactNode;
  className?: string;
}

export function CardHeader({ children, className = '' }: CardHeaderProps) {
  return (
    <div className={`p-4 border-b border-border ${className}`}>
      {children}
    </div>
  );
}

interface CardContentProps {
  children: ReactNode;
  className?: string;
}

export function CardContent({ children, className = '' }: CardContentProps) {
  return (
    <div className={`p-4 ${className}`}>
      {children}
    </div>
  );
}

interface CardFooterProps {
  children: ReactNode;
  className?: string;
}

export function CardFooter({ children, className = '' }: CardFooterProps) {
  return (
    <div className={`p-4 border-t border-border ${className}`}>
      {children}
    </div>
  );
}
