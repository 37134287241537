import React, { useState } from 'react';

interface TagInputProps {
  value?: string[];
  onChange?: (tags: string[]) => void;
  placeholder?: string;
  label?: string;
  className?: string;
  maxTags?: number;
}

export function TagInput({
  value = [],
  onChange,
  placeholder = 'Enter a tag',
  label,
  className = '',
  maxTags,
}: TagInputProps) {
  const [input, setInput] = useState('');
  const [localTags, setLocalTags] = useState<string[]>(value);

  const tags = value || localTags;
  const onTagsChange = onChange || setLocalTags;

  const addTag = () => {
    if (input.trim() && !tags.includes(input.trim()) && (!maxTags || tags.length < maxTags)) {
      onTagsChange([...tags, input.trim()]);
      setInput('');
    }
  };

  const removeTag = (tagToRemove: string) => {
    onTagsChange(tags.filter(tag => tag !== tagToRemove));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addTag();
    }
  };

  return (
    <div className={className}>
      {label && (
        <label className="block text-sm font-medium mb-1">
          {label}
        </label>
      )}
      <div className="flex gap-2 mb-2">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          className="flex-1 px-3 py-2 border rounded-lg bg-background border-border focus:outline-none focus:ring-2 focus:ring-[rgb(var(--accent-1))]"
          placeholder={maxTags ? tags.length >= maxTags ? `Maximum ${maxTags} tags` : placeholder : placeholder}
          disabled={maxTags ? tags.length >= maxTags : false}
        />
        <button
          type="button"
          onClick={addTag}
          disabled={maxTags ? tags.length >= maxTags : false}
          className="px-4 py-2 bg-[rgb(var(--accent-1))] text-white rounded-lg hover:bg-[rgb(var(--accent-1))]/90 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Add
        </button>
      </div>
      <div className="flex flex-wrap gap-2">
        {tags.map((tag) => (
          <span
            key={tag}
            className="px-3 py-1 bg-[rgb(var(--accent-1))]/10 rounded-full flex items-center gap-2"
          >
            {tag}
            <button
              type="button"
              onClick={() => removeTag(tag)}
              className="text-sm hover:text-red-500"
            >
              ×
            </button>
          </span>
        ))}
      </div>
    </div>
  );
}
