import { useState, useEffect, Suspense, lazy, useCallback } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "./contexts/ThemeContext";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { ProtectedRoute } from "./components/auth/ProtectedRoute";
import { AuthProvider, useAuth } from "./contexts/auth";
import { ModalProvider } from "./contexts/ModalContext";
import { NotificationProvider } from "./contexts/NotificationContext";
import { ChatProvider } from "./contexts/chat";
import { Toaster } from "react-hot-toast";
import NotificationService from "./services/NotificationService";
import AuthModal from "./components/auth/AuthModal";
import { OnboardingModal } from "./components/auth/OnboardingModal";
import { Loading } from "./components/common/Loading";
import { MetaTags } from './components/common/MetaTags';
import { StructuredData } from './components/common/StructuredData';
import { Analytics } from './components/common/Analytics';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import GlobalSearch from "./components/GlobalSearch";
import ChatLayout from "./components/chat/ChatLayout";

// Lazy load components
const Home = lazy(() => import("./components/Home"));
const Events = lazy(() => import("./components/Events"));
const Projects = lazy(() => import("./components/Projects"));
const ProjectView = lazy(() => import("./components/ProjectView"));
const EditProject = lazy(() => import("./components/EditProject"));
const Profile = lazy(() => import("./components/Profile"));
const Directory = lazy(() => import("./components/Directory"));
const AddEvent = lazy(() => import("./components/AddEvent"));
const AddProject = lazy(() => import("./components/AddProject"));
const SavedItems = lazy(() => import("./components/SavedItems"));
const Collaboration = lazy(() => import("./components/Collaboration"));
const Settings = lazy(() => import("./components/Settings"));
const NotFound = lazy(() => import("./components/NotFound"));
const EventView = lazy(() => import("./components/EventView"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const WritePost = lazy(() => import("./components/WritePost"));
const BlogPostPage = lazy(() => import("./components/BlogPostPage"));
const UserProfile = lazy(() => import("./components/UserProfile"));

// AppContent component that uses auth context
function AppContent() {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showGlobalSearch, setShowGlobalSearch] = useState(false);
  const { showOnboarding, setShowOnboarding } = useAuth();

  // Handle keyboard shortcuts
  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    // Command/Control + K to open search
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();
      setShowGlobalSearch(true);
    }
  }, []);

  useEffect(() => {
    // Initialize notification service
    NotificationService.initialize();

    // Add keyboard shortcut listener
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);

    // Set viewport height
    const setViewHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setViewHeight();
    window.addEventListener("resize", setViewHeight);

    return () => window.removeEventListener("resize", setViewHeight);
  }, []);

  return (
    <div className="flex min-h-screen flex-col bg-[rgb(var(--background-start-rgb))] overflow-x-hidden">
      {/* Base gradient layer */}
      <div className="fixed inset-0 bg-gradient-to-b from-transparent via-[rgb(var(--background-end-rgb))] to-[rgb(var(--background-start-rgb))] opacity-70" />

      {/* Animated gradient overlays */}
      <div className="fixed inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_0%_0%,rgba(var(--accent-1),0.15),transparent_50%),radial-gradient(circle_at_100%_0%,rgba(var(--accent-2),0.15),transparent_50%),radial-gradient(circle_at_100%_100%,rgba(var(--accent-3),0.15),transparent_50%),radial-gradient(circle_at_0%_100%,rgba(var(--glow-rgb),0.15),transparent_50%)]" />
      </div>

      {/* Content layer */}
      <div className="relative flex-1 flex flex-col z-10 min-h-[100vh]">
        <Navbar 
          onSignInClick={() => setShowAuthModal(true)}
          onSearchClick={() => setShowGlobalSearch(true)}
        />

        <main className="flex-1">
          <div className="container mx-auto px-4 sm:px-6 py-4 sm:py-8 max-w-full sm:max-w-7xl">
            <Suspense fallback={<Loading />}>
              <Routes>
                {/* Public routes */}
                <Route path="/" element={<Home />} />
                <Route path="/events" element={<Events />} />
                <Route path="/events/:eventId" element={<EventView />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/project/:slug" element={<ProjectView />} />
                <Route path="/:username/:postSlug" element={<BlogPostPage />} />
                <Route path="/:username" element={<Profile />} />
                <Route path="/user-profile/:userId" element={<UserProfile />} />

                {/* Protected routes that require onboarding */}
                <Route element={<ProtectedRoute requireOnboarding />}>
                  <Route path="/add-event" element={<AddEvent />} />
                  <Route path="/add-project" element={<AddProject />} />
                  <Route path="/projects/:projectId/edit" element={<EditProject />} />
                  <Route path="/collaboration" element={<Collaboration />} />
                </Route>

                {/* Protected routes */}
                <Route element={<ProtectedRoute />}>
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/directory" element={<Directory />} />
                  <Route path="/saved" element={<SavedItems />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/write" element={<WritePost />} />
                </Route>

                {/* 404 route */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </div>
        </main>

        <Footer />
      </div>

      {/* Modals */}
      {showAuthModal && <AuthModal isOpen={showAuthModal} onClose={() => setShowAuthModal(false)} />}
      {showOnboarding && <OnboardingModal isOpen={showOnboarding} onClose={() => setShowOnboarding(false)} />}
      
      {/* Global Search */}
      <GlobalSearch isOpen={showGlobalSearch} onClose={() => setShowGlobalSearch(false)} />

      {/* Chat */}
      <ChatLayout />

      {/* Notifications */}
      <Toaster position="bottom-right" />
    </div>
  );
}

function App() {
  return (
    <Router>
      <MetaTags />
      <StructuredData />
      <Analytics />
      <ThemeProvider>
        <AuthProvider>
          <NotificationProvider>
            <ChatProvider>
              <ModalProvider>
                <AppContent />
              </ModalProvider>
            </ChatProvider>
          </NotificationProvider>
        </AuthProvider>
      </ThemeProvider>
      <SpeedInsights />
      <VercelAnalytics />
    </Router>
  );
}

export default App;
