import React, { createContext, useContext, useState, useEffect } from "react";
import NotificationService from "../services/NotificationService";

interface Notification {
  id: string;
  message: string;
  type: "success" | "error" | "info" | "warning";
}

interface NotificationContextType {
  notifications: Notification[];
  addNotification: (message: string, type: Notification["type"]) => void;
  removeNotification: (id: string) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
);

export function NotificationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    const notificationService = NotificationService.getInstance();

    // Cast the function to the expected NotificationCallback type
    const handleNewNotification = (
      message: string,
      type: any
    ) => {
      const id = crypto.randomUUID();
      setNotifications((prev) => [...prev, { id, message, type: type as Notification["type"] }]);

      // Auto-remove notification after 5 seconds
      setTimeout(() => {
        setNotifications((prev) => prev.filter((n) => n.id !== id));
      }, 5000);
    };

    notificationService.subscribe(handleNewNotification as any);
    return () => notificationService.unsubscribe(handleNewNotification as any);
  }, []);

  const addNotification = (message: string, type: Notification["type"]) => {
    NotificationService.getInstance().addNotification(message, type);
  };

  const removeNotification = (id: string) => {
    setNotifications((prev) => prev.filter((n) => n.id !== id));
  };

  return (
    <NotificationContext.Provider
      value={{ notifications, addNotification, removeNotification }}
    >
      {children}
      {/* Notification Toast Container */}
      <div className="fixed bottom-4 right-4 z-50 space-y-2">
        {notifications.map((notification) => (
          <div
            key={notification.id}
            className={`
              p-4 rounded-lg shadow-lg backdrop-blur-lg animate-slide-in
              ${
                notification.type === "success"
                  ? "bg-success/10 text-success border border-success/20"
                  : notification.type === "error"
                  ? "bg-error/10 text-error border border-error/20"
                  : notification.type === "warning"
                  ? "bg-warning/10 text-warning border border-warning/20"
                  : "bg-info/10 text-info border border-info/20"
              }
            `}
          >
            <div className="flex items-center justify-between">
              <p>{notification.message}</p>
              <button
                onClick={() => removeNotification(notification.id)}
                className="ml-4 hover:opacity-70 transition-opacity"
              >
                ×
              </button>
            </div>
          </div>
        ))}
      </div>
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
}

export default NotificationProvider;
