type NotificationType = "success" | "error" | "info" | "warning" | "collaboration_accepted" | "collaboration_rejected" | "application_status_update" | "collaboration_application" | "chat_message";
type NotificationCallback = (message: string, type: NotificationType) => void;

interface NotificationData {
  userId: string;
  type: NotificationType;
  title: string;
  message: string;
  data?: Record<string, any>;
}

class NotificationService {
  private static instance: NotificationService;
  private subscribers: NotificationCallback[] = [];
  private initialized: boolean = false;

  private constructor() {}

  static getInstance(): NotificationService {
    if (!NotificationService.instance) {
      NotificationService.instance = new NotificationService();
    }
    return NotificationService.instance;
  }

  static initialize(): void {
    const instance = NotificationService.getInstance();
    if (!instance.initialized) {
      // Add any initialization logic here
      instance.initialized = true;
    }
  }

  subscribe(callback: NotificationCallback) {
    this.subscribers.push(callback);
  }

  unsubscribe(callback: NotificationCallback) {
    this.subscribers = this.subscribers.filter(cb => cb !== callback);
  }

  addNotification(message: string, type: NotificationType, _data?: Record<string, any>, targetUsers?: string[]) {
    // If targetUsers is specified, only show notification to those users
    // For now, we'll just show all notifications since we don't have user context in the service
    // In a real app, you'd want to check the current user's ID against targetUsers
    if (!targetUsers || targetUsers.length === 0) {
      this.subscribers.forEach(callback => callback(message, type));
    } else {
      // In a real app, you'd check the current user's ID here
      // For now, we'll just show it
      this.subscribers.forEach(callback => callback(message, type));
    }
  }

  async sendNotification(notification: NotificationData): Promise<void> {
    // For now, we'll just show the notification using addNotification
    // In a real app, you might want to store this in a database and handle push notifications
    this.addNotification(notification.message, notification.type, notification.data, [notification.userId]);
  }
}

export default NotificationService;