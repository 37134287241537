import { collection, query, where, getDocs, QueryDocumentSnapshot, DocumentData } from 'firebase/firestore';
import { db } from '../lib/firebase';

export interface SearchResult {
  id: string;
  type: 'project' | 'event' | 'collaboration' | 'user';
  title: string;
  description: string;
  tags: string[];
  imageUrl?: string;
  creator: {
    id: string;
    name: string;
    avatar: string;
  };
  createdAt: Date;
  additionalData: Record<string, any>;
}

class SearchService {
  private static instance: SearchService;

  private constructor() {}

  public static getInstance(): SearchService {
    if (!SearchService.instance) {
      SearchService.instance = new SearchService();
    }
    return SearchService.instance;
  }

  private async searchCollection(
    collectionName: string,
    searchTerm: string,
    filters: Record<string, any> = {},
    type: SearchResult['type']
  ): Promise<SearchResult[]> {
    const results: SearchResult[] = [];
    const collectionRef = collection(db, collectionName);
    
    // Get all documents first
    const querySnapshot = await getDocs(collectionRef);

    // Client-side filtering for better search results
    querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
      const data = doc.data();
      const searchableText = [
        data.title,
        data.description,
        ...(data.tags || []),
        data.creator?.name
      ].filter(Boolean).join(' ').toLowerCase();

      // Check if the document matches the search term
      if (!searchTerm || searchableText.includes(searchTerm.toLowerCase())) {
        // Check if document matches filters
        const matchesFilters = Object.entries(filters).every(([key, value]) => {
          if (!value || value === 'all') return true;
          return data[key] === value;
        });

        if (matchesFilters) {
          results.push({
            id: doc.id,
            type,
            title: data.title,
            description: data.description,
            tags: data.tags || [],
            imageUrl: data.imageUrl,
            creator: data.creator || {
              id: 'unknown',
              name: 'Unknown User',
              avatar: ''
            },
            createdAt: data.createdAt?.toDate() || new Date(),
            additionalData: data
          });
        }
      }
    });

    // Sort results by relevance and date
    return results.sort((a, b) => {
      if (searchTerm) {
        // Prioritize exact matches in title
        const aExactMatch = a.title.toLowerCase().includes(searchTerm.toLowerCase());
        const bExactMatch = b.title.toLowerCase().includes(searchTerm.toLowerCase());
        if (aExactMatch && !bExactMatch) return -1;
        if (!aExactMatch && bExactMatch) return 1;
      }
      // Then sort by date
      return b.createdAt.getTime() - a.createdAt.getTime();
    });
  }

  public async globalSearch(
    searchTerm: string,
    filters: {
      types?: Array<SearchResult['type']>;
      [key: string]: any;
    } = {}
  ): Promise<SearchResult[]> {
    const searchPromises: Promise<SearchResult[]>[] = [];
    const types = filters.types || ['project', 'event', 'collaboration', 'user'];

    if (types.includes('project')) {
      searchPromises.push(
        this.searchCollection('projects', searchTerm, filters, 'project')
      );
    }

    if (types.includes('event')) {
      searchPromises.push(
        this.searchCollection('events', searchTerm, filters, 'event')
      );
    }

    if (types.includes('collaboration')) {
      searchPromises.push(
        this.searchCollection('collaborations', searchTerm, filters, 'collaboration')
      );
    }

    if (types.includes('user')) {
      searchPromises.push(
        this.searchCollection('users', searchTerm, filters, 'user')
      );
    }

    const results = await Promise.all(searchPromises);
    return results.flat().sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
  }

  public async searchByTags(tags: string[]): Promise<SearchResult[]> {
    if (!tags.length) return [];

    const collections = ['projects', 'events', 'collaborations'];
    const searchPromises = collections.map(collectionName => {
      const collectionRef = collection(db, collectionName);
      const q = query(collectionRef, where('tags', 'array-contains-any', tags));
      return getDocs(q);
    });

    const snapshots = await Promise.all(searchPromises);
    const results: SearchResult[] = [];

    snapshots.forEach((snapshot, index) => {
      snapshot.forEach(doc => {
        const data = doc.data();
        results.push({
          id: doc.id,
          type: collections[index].slice(0, -1) as SearchResult['type'],
          title: data.title,
          description: data.description,
          tags: data.tags || [],
          imageUrl: data.imageUrl,
          creator: data.creator,
          createdAt: data.createdAt?.toDate(),
          additionalData: data
        });
      });
    });

    return results.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
  }
}

export default SearchService;
