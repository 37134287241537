import React from "react";
import { Loader2 } from "lucide-react";
import { cn } from "../../utils/cn";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | "primary"
    | "secondary"
    | "outline"
    | "ghost"
    | "danger"
    | "default"
    | "link";
  size?: "sm" | "md" | "lg";
  isLoading?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

export function Button({
  children,
  variant = "primary",
  size = "md",
  isLoading = false,
  leftIcon,
  rightIcon,
  className = "",
  disabled,
  ...props
}: ButtonProps) {
  const baseStyles =
    "inline-flex items-center justify-center gap-2 font-medium rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed";

  const variants = {
    primary:
      "bg-[rgb(var(--accent-1))] text-white hover:bg-[rgb(var(--accent-1))]/90 focus:ring-[rgb(var(--accent-1))]",
    secondary:
      "bg-[rgb(var(--accent-2))] text-white hover:bg-[rgb(var(--accent-2))]/90 focus:ring-[rgb(var(--accent-2))]",
    outline:
      "border border-border hover:bg-accent/10 focus:ring-[rgb(var(--accent-1))]",
    ghost: "hover:bg-accent/10 focus:ring-[rgb(var(--accent-1))]",
    danger: "bg-red-500 text-white hover:bg-red-600 focus:ring-red-500",
    default: "bg-gray-200 text-black hover:bg-gray-300 focus:ring-gray-200",
    link: "underline-offset-4 hover:underline text-primary",
  };

  const sizes = {
    sm: "px-3 py-1.5 text-sm",
    md: "px-4 py-2",
    lg: "px-6 py-3 text-lg",
  };

  return (
    <button
      className={cn(baseStyles, variants[variant], sizes[size], className)}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading && <Loader2 className="h-4 w-4 animate-spin" />}
      {!isLoading && leftIcon}
      {children}
      {!isLoading && rightIcon}
    </button>
  );
}
