import { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Send, ChevronDown } from 'lucide-react';
import { Button } from '../common/Button';
import { Input } from '../common/Input';
import { Avatar } from '../common/Avatar';
import RealtimeService, { ChatThread, Message } from '../../services/RealtimeService';
import { useAuth } from '../../contexts/auth';

// Memoized message component
const ChatMessage = memo(({ message, isOwnMessage }: { 
  message: Message; 
  isOwnMessage: boolean; 
}) => (
  <div
    className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'}`}
  >
    <div className={`flex items-start space-x-2 max-w-[80%] ${isOwnMessage ? 'flex-row-reverse space-x-reverse' : ''}`}>
      <Avatar
        src={message.sender.avatar}
        alt={message.sender.name}
        className="w-8 h-8"
      />
      <div>
        <div className={`rounded-lg p-3 ${
          isOwnMessage
            ? 'bg-primary text-primary-foreground'
            : 'bg-muted'
        }`}>
          {message.type === 'text' ? (
            <p className="text-sm whitespace-pre-wrap break-words">{message.content}</p>
          ) : (
            <p className="text-sm italic">{message.content}</p>
          )}
        </div>
        <p className="text-xs text-muted-foreground mt-1">
          {message.sender.name} • {new Date(message.timestamp).toLocaleTimeString()}
        </p>
      </div>
    </div>
  </div>
));

interface ChatConversationProps {
  thread: ChatThread;
  onClose: () => void;
  isMinimized: boolean;
  onMinimize: () => void;
}

function ChatConversation({ thread, onClose, isMinimized, onMinimize }: ChatConversationProps) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [userProfiles, setUserProfiles] = useState<Record<string, any>>({});
  // Loading state for future implementation
  const [_loading, setLoading] = useState(true);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const realtimeServiceRef = useRef(RealtimeService.getInstance());
  const { user } = useAuth();

  // Memoize user profiles loading function with batch loading
  const loadProfiles = useCallback(async () => {
    if (thread.participants.length === 0) return;

    try {
      // Load all profiles in parallel
      const profilePromises = thread.participants.map(participantId =>
        realtimeServiceRef.current.getUserProfile(participantId)
      );
      const profiles = await Promise.all(profilePromises);
      
      // Convert to record format
      const profilesRecord = profiles.reduce((acc, profile, index) => {
        if (profile) {
          acc[thread.participants[index]] = profile;
        }
        return acc;
      }, {} as Record<string, any>);

      setUserProfiles(profilesRecord);
    } catch (error) {
      console.error('Error loading profiles:', error);
    }
  }, [thread.participants]);

  // Memoize message sending handler
  const handleSendMessage = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !newMessage.trim()) return;

    try {
      await realtimeServiceRef.current.sendMessageToThread(thread.id, {
        content: newMessage.trim(),
        sender: {
          id: user.uid,
          name: userProfiles[user.uid]?.name || 'Unknown User',
          avatar: userProfiles[user.uid]?.avatar || ''
        },
        type: 'text'
      });
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }, [newMessage, user, thread.id, userProfiles]);

  // Memoize message input handler
  const handleMessageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
  }, []);

  // Memoize scroll to bottom function
  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  // Effect for loading profiles and subscribing to messages
  useEffect(() => {
    if (!user) {
      console.log('No user found, skipping thread subscription');
      return;
    }

    setLoading(true);
    loadProfiles();

    // Subscribe to thread messages
    const unsubscribe = realtimeServiceRef.current.subscribeToChatThread(thread.id, (updatedThread) => {
      if (updatedThread.messages) {
        const messageList = updatedThread.messages;
        setMessages(messageList.sort((a, b) => a.timestamp - b.timestamp));
        scrollToBottom();

        // Mark messages as read when they are viewed
        const hasUnreadMessages = messageList.some(msg => 
          !msg.read && msg.sender.id !== user.uid
        );

        if (hasUnreadMessages && !isMinimized) {
          realtimeServiceRef.current.markMessagesAsRead(thread.id, user.uid)
            .catch(error => console.error('Error marking messages as read:', error));
        }
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [user, thread.id, loadProfiles, scrollToBottom, isMinimized]);

  // Mark messages as read when chat is unminimized
  useEffect(() => {
    if (!isMinimized && user && messages.length > 0) {
      const hasUnreadMessages = messages.some(msg => 
        !msg.read && msg.sender.id !== user.uid
      );

      if (hasUnreadMessages) {
        realtimeServiceRef.current.markMessagesAsRead(thread.id, user.uid)
          .catch(error => console.error('Error marking messages as read:', error));
      }
    }
  }, [isMinimized, user, messages, thread.id]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{
        opacity: 1,
        y: 0,
        height: isMinimized ? '64px' : '600px'
      }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      className="w-[320px] bg-card border rounded-lg shadow-lg flex flex-col overflow-hidden"
    >
      {/* Header */}
      <motion.div 
        className="p-3 border-b flex items-center justify-between cursor-pointer"
        onClick={onMinimize}
        animate={{ 
          borderBottomWidth: isMinimized ? '0px' : '1px',
          borderBottomColor: isMinimized ? 'transparent' : 'var(--border)'
        }}
        transition={{ duration: 0.2 }}
      >
        <div className="flex items-center space-x-2">
          <h3 className="font-semibold truncate">{thread.title}</h3>
        </div>
        <div className="flex items-center space-x-2">
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={(e) => {
              e.stopPropagation();
              onMinimize();
            }}
          >
            <motion.div
              initial={false}
              animate={{ rotate: isMinimized ? 180 : 0 }}
              transition={{ duration: 0.2 }}
            >
              <ChevronDown className="h-4 w-4" />
            </motion.div>
          </Button>
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
      </motion.div>

      {/* Messages */}
      <AnimatePresence>
        {!isMinimized && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex-1 overflow-y-auto p-4 space-y-4 min-h-0"
          >
            {useMemo(() => (
              messages.map((message) => (
                <ChatMessage
                  key={message.id}
                  message={message}
                  isOwnMessage={message.sender.id === user?.uid}
                />
              ))
            ), [messages, user])}
            <div ref={messagesEndRef} />
          </motion.div>
        )}
      </AnimatePresence>

      {/* Message Input */}
      <AnimatePresence>
        {!isMinimized && (
          <motion.form
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            onSubmit={handleSendMessage}
            className="p-4 border-t"
          >
            <div className="flex items-center space-x-2">
              <Input
                type="text"
                placeholder="Type a message..."
                value={newMessage}
                onChange={handleMessageChange}
                className="flex-1"
              />
              <Button type="submit" size="sm" disabled={!newMessage.trim()}>
                <Send className="h-4 w-4" />
              </Button>
            </div>
          </motion.form>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

// Export memoized component
export default memo(ChatConversation);
