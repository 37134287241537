import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageCircle, ChevronDown, Users } from 'lucide-react';
import { Button } from '../common/Button';
import { Avatar } from '../common/Avatar';
import ChatThreadList from './ChatThreadList';
import ChatConversation from './ChatConversation';
import RealtimeService, { ChatThread } from '../../services/RealtimeService';
import CacheService from '../../services/CacheService';
import { useAuth } from '../../contexts/auth';
import { useChat } from '../../contexts/chat';

interface OnlineUser {
  id: string;
  name: string;
  avatar: string;
  status: 'online' | 'offline';
  lastSeen: number;
}

export default function ChatLayout() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [showOnlineUsers, setShowOnlineUsers] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState<OnlineUser[]>([]);
  const { user } = useAuth();
  const { unreadCount, selectedThread, setSelectedThread } = useChat();
  const realtimeService = RealtimeService.getInstance();
  const cache = CacheService.getInstance();



  // Reset states when user changes
  useEffect(() => {
    setIsOpen(false);
    setSelectedThread(null);
    setIsMinimized(false);
    setShowOnlineUsers(false);
  }, [user?.uid]);

  // Subscribe to online users
  useEffect(() => {
    if (!user) return;

    // Try to get cached online users first
    cache.getItem<OnlineUser[]>('online_users').then((cachedUsers) => {
      if (cachedUsers) {
        setOnlineUsers(cachedUsers);
      }
    });

    // Subscribe to online users
    const unsubscribe = realtimeService.subscribeToOnlineUsers((users) => {
      const sortedUsers = users
        .filter((u) => u.id !== user.uid)
        .sort((a, b) => {
          if (a.status === b.status) {
            return b.lastSeen - a.lastSeen;
          }
          return a.status === 'online' ? -1 : 1;
        });

      cache.setItem('online_users', sortedUsers);
      setOnlineUsers(sortedUsers);
    });

    return () => unsubscribe();
  }, [user, cache, realtimeService]);

  const startChat = async (userId: string) => {
    if (!user) return;
    
    try {
      // Send initial message
      await realtimeService.sendDirectMessage(user.uid, userId, "👋 Hi! I'd like to chat with you.");
      
      // Create a room ID manually (same logic as in RealtimeService)
      const roomId = [user.uid, userId].sort().join('_');
      const threads = await realtimeService.getChatThreads({
        type: 'direct_message',
        roomId
      });
      
      if (threads.length > 0) {
        setIsOpen(true);
        setSelectedThread(threads[0]);
        setShowOnlineUsers(false);
      }
    } catch (error) {
      console.error('Error starting chat:', error);
    }
  };

  const handleThreadSelect = async (thread: ChatThread) => {
    setSelectedThread(thread);
    setIsMinimized(false);
    // Mark messages as read when thread is selected
    if (user && thread.id) {
      await realtimeService.markMessagesAsRead(thread.id, user.uid);
    }
  };

  const handleClose = () => {
    setSelectedThread(null);
  };

  const toggleChat = () => {
    if (isMinimized) {
      setIsMinimized(false);
    } else {
      setIsOpen(!isOpen);
      if (!isOpen) {
        setSelectedThread(null);
        setShowOnlineUsers(false);
      }
    }
  };

  const toggleOnlineUsers = () => {
    setShowOnlineUsers(!showOnlineUsers);
    if (!showOnlineUsers) {
      setIsOpen(true);
      setSelectedThread(null);
    }
  };

  return (
    <>
      <Button
        variant="ghost"
        size="lg"
        className="fixed bottom-4 right-4 rounded-full shadow-lg z-50"
        onClick={toggleChat}
      >
        <div className="relative">
          <MessageCircle className="h-6 w-6" />
          {unreadCount > 0 && (
            <div className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
              {unreadCount > 9 ? '9+' : unreadCount}
            </div>
          )}
        </div>
      </Button>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="fixed bottom-20 right-4 z-50 flex gap-4 items-end"
            >
              {/* Chat Container */}
              {!selectedThread && (
                <motion.div
                  className="bg-background rounded-lg shadow-lg w-full lg:w-96 overflow-hidden"
                  initial={{ scale: 0.95 }}
                  animate={{ scale: 1 }}
                >
                  <div className="p-4 border-b flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <h3 className="font-semibold">Chat</h3>
                      {unreadCount > 0 && (
                        <div className="bg-red-500 text-white text-xs rounded-full px-2 py-0.5">
                          {unreadCount > 99 ? '99+' : unreadCount}
                        </div>
                      )}
                    </div>
                    <div className="flex gap-2">
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={toggleOnlineUsers}
                      >
                        <Users className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={toggleChat}
                      >
                        <ChevronDown className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>

                  <div className="h-96 overflow-y-auto">
                    {showOnlineUsers ? (
                      <div className="p-4">
                        <h4 className="font-medium mb-4">Online Users</h4>
                        {onlineUsers.length > 0 ? (
                          onlineUsers.map((user) => (
                            <Button
                              key={user.id}
                              variant="ghost"
                              className="w-full justify-start px-4 py-2"
                              onClick={() => startChat(user.id)}
                            >
                              <div className="flex items-center space-x-3">
                                <div className="relative">
                                  <Avatar
                                    src={user.avatar}
                                    alt={user.name}
                                    className="h-8 w-8"
                                  />
                                  <div
                                    className={`absolute bottom-0 right-0 h-2.5 w-2.5 rounded-full border-2 border-background ${
                                      user.status === 'online' ? 'bg-green-500' : 'bg-gray-400'
                                    }`}
                                  />
                                </div>
                                <div className="flex-1 min-w-0">
                                  <p className="truncate font-medium">{user.name}</p>
                                  <p className="text-sm text-muted-foreground truncate">
                                    {user.status === 'online' ? 'Online' : 'Offline'}
                                  </p>
                                </div>
                              </div>
                            </Button>
                          ))
                        ) : (
                          <div className="text-center text-muted-foreground p-4">
                            <p>No users online</p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <ChatThreadList onSelectThread={handleThreadSelect} />
                    )}
                  </div>
                </motion.div>
              )}

              {/* Active Chat */}
              <AnimatePresence>
                {selectedThread && (
                  <motion.div
                    initial={{ x: 20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: 20, opacity: 0 }}
                    transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                    className="bg-background rounded-lg shadow-lg w-full lg:w-96 overflow-hidden"
                  >
                    <ChatConversation
                      thread={selectedThread}
                      onClose={handleClose}
                      isMinimized={isMinimized}
                      onMinimize={() => setIsMinimized(!isMinimized)}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
}