import { useState } from "react";
import { Avatar } from "./common/Avatar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Home,
  Users,
  Calendar,
  Menu,
  X,
  LogOut,
  Layers,
  Users2,
  Bookmark,
  Settings,
  LogIn,
  Search,
  // Command,
} from "lucide-react";
import Notifications from "./Notifications";
import { useAuth } from "../contexts/auth";
import { toast } from "react-hot-toast";
import { Button } from "./common/Button";

interface NavbarProps {
  onSignInClick: () => void;
  onSearchClick: () => void;
}

export default function Navbar({ onSignInClick, onSearchClick }: NavbarProps) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user, loading, logout, profile } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      toast.success("Successfully signed out");
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
      toast.error("Failed to sign out");
    }
  };

  const NavLinks = () => {
    const { user, loading } = useAuth();
    
    if (loading) return null;
    const links = [
      ...(user
        ? [{ to: "/dashboard", icon: <Home size={20} />, text: "Dashboard" }]
        : [{ to: "/", icon: <Home size={20} />, text: "Home" }]),
      { to: "/projects", icon: <Layers size={20} />, text: "Projects" },
      { to: "/events", icon: <Calendar size={20} />, text: "Events" },
      {
        to: "/directory",
        icon: <Users2 size={20} />,
        text: "Directory",
        protected: true,
      },
      {
        to: "/collaboration",
        icon: <Users size={20} />,
        text: "Collaboration",
        protected: true,
      },
      {
        to: "/saved",
        icon: <Bookmark size={20} />,
        text: "Saved",
        protected: true,
      },
    ];

    return (
      <>
        {links.map(({ to, icon, text, protected: isProtected }) => {
          if (isProtected && !user) return null;

          return (
            <Link
              key={to}
              to={to}
              className={`flex items-center gap-2 p-2 rounded-lg transition-colors hover:bg-accent ${
                location.pathname === to
                  ? "bg-accent text-[rgb(var(--accent-1))]"
                  : ""
              }`}
            >
              {icon}
              <span>{text}</span>
            </Link>
          );
        })}
      </>
    );
  };

  const AuthSection = () => {
    if (loading) {
      return (
        <div className="flex items-center gap-2">
          <div className="w-8 h-8 rounded-full bg-surface/50 animate-pulse" />
          <div className="h-4 w-20 bg-surface/50 animate-pulse rounded" />
        </div>
      );
    }

    if (user && profile) {
      return (
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => navigate("/settings")}
            leftIcon={<Settings size={20} />}
            className="p-2"
          />
          <Link
            to="/profile"
            className="relative flex items-center justify-center w-8 h-8 rounded-full overflow-hidden hover:ring-2 hover:ring-[rgb(var(--accent-1))] transition-all"
            title={profile.displayName || "Profile"}
          >
            <Avatar
              src={profile.photoURL}
              alt={profile.displayName || "Profile"}
              className="w-full h-full"
            />
          </Link>
          <Notifications />
          <Button
            variant="ghost"
            size="sm"
            onClick={handleLogout}
            leftIcon={<LogOut size={20} />}
            className="p-2"
          />
        </div>
      );
    }

    return (
      <Button
        onClick={onSignInClick}
        leftIcon={<LogIn size={20} />}
        variant="primary"
        className="bg-surface border border-[rgb(var(--accent-1))] text-[rgb(var(--accent-1))] hover:bg-[rgb(var(--accent-1))] hover:text-white transition-colors"
      >
        Sign In
      </Button>
    );
  };

  return (
    <nav className="sticky top-0 z-50 bg-surface/80 backdrop-blur-lg border-b border-white/10 w-full">
      <div className="container mx-auto px-4 sm:px-6 max-w-full sm:max-w-7xl">
        <div className="flex items-center justify-between h-16">
          {/* Logo and brand */}
          <div className="flex items-center gap-2">
            <Link to="/" className="flex items-center gap-1">
              <img
                src="https://pub-1e5c9c2062ff42fbb6b8a2d9e7a8a1e2.r2.dev/LaunchPadLogo.png"
                alt="Launchpad"
                className="h-16 sm:h-24 md:h-32"
              />
            </Link>

            {/* Desktop navigation */}
            <div className="hidden md:flex items-center gap-2">
              <NavLinks />
            </div>
          </div>

          {/* Desktop auth section */}
          <div className="hidden md:flex items-center gap-4">
            {/* Search trigger */}
            <button
              onClick={onSearchClick}
              className="flex items-center gap-2 px-3 py-1.5 text-sm text-muted-foreground rounded-md border hover:bg-accent transition-colors"
            >
              <Search size={16} />
              <span>Search...</span>
              <kbd className="ml-2 text-xs border px-1.5 py-0.5 rounded bg-muted">
                ⌘K
              </kbd>
            </button>
            <AuthSection />
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="p-2 rounded-lg hover:bg-accent"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden border-t border-white/10 w-full">
          <div className="container mx-auto px-4 py-4 space-y-3 flex flex-col">
            <NavLinks />
            <div className="pt-4 border-t border-white/10">
              <AuthSection />
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}