import { useEffect } from 'react';
import { Modal } from '../common/Modal';
import SignIn from './SignIn';
import { useAuth } from '../../contexts/auth';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function AuthModal({ isOpen, onClose }: AuthModalProps) {
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      onClose();
    }
  }, [user, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Sign In"
    >
      <SignIn onSuccess={onClose} />
    </Modal>
  );
}

export default AuthModal;
