import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  WhereFilterOp,
  orderBy,
  OrderByDirection,
  limit,
  DocumentData,
  WithFieldValue,
  CollectionReference,
  Query,
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { UserDocument } from '../types/documents';

export class FirestoreService<T extends { id: string }> {
  private collectionName: string;
  private collectionRef: CollectionReference;

  constructor(collectionName: string) {
    this.collectionName = collectionName;
    this.collectionRef = collection(db, collectionName);
  }

  async get(id: string): Promise<T | null> {
    try {
      const docRef = doc(db, this.collectionName, id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() } as T;
      }
      return null;
    } catch (error) {
      console.error('Error getting document:', error);
      return null;
    }
  }

  async getAll(): Promise<T[]> {
    try {
      const querySnapshot = await getDocs(this.collectionRef);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as T));
    } catch (error) {
      console.error('Error getting documents:', error);
      return [];
    }
  }

  async add(data: WithFieldValue<Omit<T, 'id'>>): Promise<string | null> {
    try {
      const docRef = await addDoc(this.collectionRef, {
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        ...data as DocumentData,
      });
      return docRef.id;
    } catch (error) {
      console.error('Error adding document:', error);
      return null;
    }
  }

  async update(id: string, data: Partial<T>): Promise<boolean> {
    try {
      const docRef = doc(db, this.collectionName, id);
      await updateDoc(docRef, {
        ...data as DocumentData,
        updatedAt: new Date().toISOString(),
      });
      return true;
    } catch (error) {
      console.error('Error updating document:', error);
      return false;
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      const docRef = doc(db, this.collectionName, id);
      await deleteDoc(docRef);
      return true;
    } catch (error) {
      console.error('Error deleting document:', error);
      return false;
    }
  }

  async query(
    conditions: { field: string; operator: WhereFilterOp; value: any }[],
    orderByField?: string,
    orderDirection?: OrderByDirection,
    limitCount?: number
  ): Promise<T[]> {
    try {
      let q: Query = this.collectionRef;

      conditions.forEach(({ field, operator, value }) => {
        q = query(q, where(field, operator, value));
      });

      if (orderByField) {
        q = query(q, orderBy(orderByField, orderDirection));
      }

      if (limitCount) {
        q = query(q, limit(limitCount));
      }

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as T));
    } catch (error) {
      console.error('Error querying documents:', error);
      return [];
    }
  }

  async getByIds(ids: string[]): Promise<T[]> {
    try {
      const items = await Promise.all(
        ids.map(async (id) => await this.get(id))
      );
      return items.filter((item) => item !== null) as T[];
    } catch (error) {
      console.error('Error getting documents by ids:', error);
      return [];
    }
  }

  async toggleStar(itemId: string, userId: string): Promise<boolean> {
    try {
      const docRef = doc(db, this.collectionName, itemId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error('Document not found');
      }

      const data = docSnap.data() as T & { likes: string[] };
      const likes = data.likes || [];
      const isLiked = likes.includes(userId);

      await updateDoc(docRef, {
        likes: isLiked ? likes.filter(id => id !== userId) : [...likes, userId],
        updatedAt: new Date().toISOString(),
      });

      return true;
    } catch (error) {
      console.error('Error toggling star:', error);
      return false;
    }
  }

  async toggleSave(itemId: string, userId: string, type: 'project' | 'event'): Promise<boolean> {
    try {
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);
      if (!userSnap.exists()) {
        throw new Error('User not found');
      }

      const userData = userSnap.data() as UserDocument;
      const savedItems = type === 'project' ? (userData.savedProjects || []) : (userData.savedEvents || []);
      const isSaved = savedItems.includes(itemId);

      await updateDoc(userRef, {
        [type === 'project' ? 'savedProjects' : 'savedEvents']: isSaved
          ? savedItems.filter(id => id !== itemId)
          : [...savedItems, itemId],
        updatedAt: new Date().toISOString(),
      });

      return true;
    } catch (error) {
      console.error('Error toggling save:', error);
      return false;
    }
  }
}

export interface EventDocument {
  id: string;
  title: string;
  description: string;
  date: string;
  time: string;
  location: string;
  type: 'meetup' | 'conference' | 'workshop';
  attendees: string[];
  organizerId: string;
  maxAttendees?: number;
  isOnline: boolean;
  meetingLink?: string;
  organizer?: {
    id: string;
    name: string;
    avatar: string;
  };
  createdAt: string;
  updatedAt: string;
}

export interface ProjectDocument {
  id: string;
  title: string;
  description: string;
  imageUrl?: string;
  tags: string[];
  websiteUrl?: string;
  githubUrl?: string;
  creatorId: string;
  stage: 'idea' | 'building' | 'launched' | 'growing';
  lookingFor: string[];
  likes: string[];
  createdAt: string;
  updatedAt: string;
}

export const usersService = new FirestoreService<UserDocument>('users');
export const eventsService = new FirestoreService<EventDocument>('events');
export const projectsService = new FirestoreService<ProjectDocument>('projects');
