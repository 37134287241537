export function MetaTags() {
  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content="Launchpad - A platform for developers to showcase their projects and connect with others" />
      <meta name="keywords" content="developers, projects, portfolio, collaboration" />
      <meta name="theme-color" content="#000000" />
    </>
  );
}
