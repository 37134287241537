import { ref, onValue, set, push, get,  query, orderByChild, limitToLast } from 'firebase/database';
import { realtimeDb } from '../lib/firebase';
import CacheService from './CacheService';
import ProfileService from './ProfileService';
import NotificationService from './NotificationService';

export interface Message {
  id: string;
  content: string;
  sender: {
    id: string;
    name: string;
    avatar: string;
  };
  timestamp: number;
  read: boolean;
  type?: 'text' | 'status_update' | 'system';
  metadata?: {
    visibleTo?: string[];
    [key: string]: any;
  };
}

export interface ChatThread {
  id: string;
  title: string;
  participants: string[];
  metadata?: {
    type: string;
    postId?: string;
    applicationId?: string;
    status?: string;
    roomId?: string; // For direct message rooms
    [key: string]: any;
  };
  createdAt: number;
  updatedAt: number;
  lastMessage?: Message;
  messages?: Message[];
}

// Alias ChatRoom to ChatThread for backward compatibility
export type ChatRoom = ChatThread;

class RealtimeService {
  private static instance: RealtimeService;
  private messageListeners: Map<string, () => void>;
  private presenceListeners: Map<string, () => void>;
  private typingListeners: Map<string, () => void>;
  private threadListeners: Map<string, () => void>;
  private userThreadListeners: Map<string, () => void>;

  private currentUserId: string | null = null;

  private constructor() {
    this.userThreadListeners = new Map();
    this.messageListeners = new Map();
    this.presenceListeners = new Map();
    this.typingListeners = new Map();
    this.threadListeners = new Map();
    this.setupOfflineSupport();
  }

  private getCurrentUserId(): string | null {
    return this.currentUserId;
  }

  private sendChatNotifications(thread: ChatThread, messages: Message[], currentUserId: string): void {
    messages.forEach(message => {
      // Don't send notifications for messages from the current user
      if (message.sender.id !== currentUserId) {
        const notificationService = NotificationService.getInstance();
        notificationService.addNotification(
          `${message.sender.name}: ${message.content}`,
          'chat_message',
          { threadId: thread.id },
          [currentUserId]
        );
      }
    });
  }

  public setCurrentUserId(userId: string | null): void {
    this.currentUserId = userId;
  }

  private setupOfflineSupport(): void {
    const cache = CacheService.getInstance();

    // When going offline, save current state
    window.addEventListener('offline', () => {
      this.messageListeners.forEach((_, roomId) => {
        get(ref(realtimeDb, `messages/${roomId}`)).then((snapshot) => {
          const messagesArray: Message[] = [];
          snapshot.forEach((childSnapshot) => {
            messagesArray.push(childSnapshot.val());
          });
          cache.setItem(`messages_${roomId}`, messagesArray);
        });
      });
    });
  }

  public static getInstance(): RealtimeService {
    if (!RealtimeService.instance) {
      RealtimeService.instance = new RealtimeService();
    }
    return RealtimeService.instance;
  }

  // User Presence
  public async updateUserPresence(userId: string, status: 'online' | 'offline'): Promise<void> {
    const presenceRef = ref(realtimeDb, `presence/${userId}`);
    await set(presenceRef, {
      status,
      lastSeen: Date.now(),
    });
  }

  public subscribeToUserPresence(userId: string, callback: (status: 'online' | 'offline') => void): () => void {
    const presenceRef = ref(realtimeDb, `presence/${userId}`);
    const unsubscribe = onValue(presenceRef, (snapshot) => {
      const data = snapshot.val();
      callback(data?.status || 'offline');
    });

    this.presenceListeners.set(userId, unsubscribe);
    return unsubscribe;
  }

  public subscribeToOnlineUsers(callback: (users: Array<{
    id: string;
    name: string;
    avatar: string;
    status: 'online' | 'offline';
    lastSeen: number;
  }>) => void): () => void {
    const presenceRef = ref(realtimeDb, 'presence');
    const unsubscribe = onValue(presenceRef, async (snapshot) => {
      const presenceData = snapshot.val() || {};
      const users = [];

      for (const [userId, data] of Object.entries(presenceData)) {
        const userProfile = await this.getUserProfile(userId);
        if (userProfile) {
          users.push({
            ...userProfile,
            status: (data as any).status || 'offline',
            lastSeen: (data as any).lastSeen || Date.now()
          });
        }
      }

      callback(users);
    });

    return unsubscribe;
  }

  public async getUserProfile(userId: string): Promise<any> {
    const profile = await ProfileService.getInstance().getProfile(userId);
    if (!profile) {
      // If no profile exists, create a minimal one in realtime db for presence
      const userRef = ref(realtimeDb, `users/${userId}`);
      await set(userRef, {
        id: userId,
        name: 'Unknown User',
        avatar: ''
      });
      return {
        id: userId,
        name: 'Unknown User',
        avatar: ''
      };
    }
    
    // Update realtime db with latest profile info
    const userRef = ref(realtimeDb, `users/${userId}`);
    const realtimeProfile = {
      id: userId,
      name: profile.displayName,
      avatar: profile.photoURL || ''
    };
    await set(userRef, realtimeProfile);
    return realtimeProfile;
  }

  public subscribeToTyping(userId: string, callback: (isTyping: boolean) => void): () => void {
    const typingRef = ref(realtimeDb, `typing/${userId}`);
    const unsubscribe = onValue(typingRef, (snapshot) => {
      callback(!!snapshot.val());
    });

    this.typingListeners.set(userId, unsubscribe);
    return unsubscribe;
  }

  public async updateTypingStatus(userId: string, isTyping: boolean): Promise<void> {
    const typingRef = ref(realtimeDb, `typing/${userId}`);
    await set(typingRef, isTyping);
  }

  // Direct Messaging
  public async sendDirectMessage(senderId: string, receiverId: string, content: string): Promise<void> {
    const cache = CacheService.getInstance();
    const roomId = this.getChatRoomId(senderId, receiverId);
    
    // Check if a thread already exists for this room
    const existingThreads = await this.getChatThreads({
      type: 'direct_message',
      roomId
    });

    let threadId: string;
    if (existingThreads.length > 0) {
      threadId = existingThreads[0].id;
    } else {
      // Create a new thread for this direct message
      threadId = await this.createChatThread({
        title: 'Direct Message',
        participants: [senderId, receiverId],
        metadata: {
          type: 'direct_message',
          roomId
        },
        createdAt: Date.now(),
        updatedAt: Date.now()
      });
    }

    const message: Partial<Message> = {
      content,
      sender: {
        id: senderId,
        name: '', // Will be updated from user profile
        avatar: '',
      },
      read: false,
    };

    if (cache.getNetworkStatus() === 'offline') {
      // Use a public method instead of private queueOperation
      await cache.setItem(`pending_message_${Date.now()}`, {
        type: 'create',
        collection: 'messages',
        data: { threadId, message }
      });
      return;
    }

    await this.sendMessageToThread(threadId, message);
  }

  public subscribeToMessages(roomId: string, callback: (messages: Message[]) => void): () => void {
    const cache = CacheService.getInstance();
    const messagesRef = ref(realtimeDb, `messages/${roomId}`);

    // Try to get cached messages first
    cache.getItem<Message[]>(`messages_${roomId}`).then((cachedMessages) => {
      if (cachedMessages) {
        callback(cachedMessages.sort((a, b) => a.timestamp - b.timestamp));
      }
    });

    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const messages: Message[] = [];
      snapshot.forEach((childSnapshot) => {
        messages.push(childSnapshot.val() as Message);
      });
      const sortedMessages = messages.sort((a, b) => a.timestamp - b.timestamp);
      
      // Update cache
      cache.setItem(`messages_${roomId}`, sortedMessages);
      callback(sortedMessages);
    });

    this.messageListeners.set(roomId, unsubscribe);
    return unsubscribe;
  }

  // Chat Thread Management
  public async createChatThread(thread: Omit<ChatThread, 'id'>): Promise<string> {
    const threadsRef = ref(realtimeDb, 'threads');
    const newThreadRef = push(threadsRef);
    const threadId = newThreadRef.key!;
    const timestamp = Date.now();

    // Ensure all required fields are present and properly formatted
    if (!thread.title || typeof thread.title !== 'string') {
      throw new Error('Thread title is required and must be a string');
    }
    if (!Array.isArray(thread.participants) || thread.participants.length === 0) {
      throw new Error('Thread participants must be a non-empty array');
    }

    const newThread: ChatThread = {
      ...thread,
      id: threadId,
      title: thread.title.trim(),
      participants: thread.participants,
      messages: [], // Initialize empty messages array
      createdAt: thread.createdAt || timestamp,
      updatedAt: thread.updatedAt || timestamp,
      metadata: thread.metadata || { type: 'default' }
    };

    // Create messages collection for this thread
    const messagesRef = ref(realtimeDb, `messages/${threadId}`);
    await set(messagesRef, null);

    console.log('RealtimeService: Creating new chat thread:', {
      id: threadId,
      title: newThread.title,
      participantCount: newThread.participants.length
    });

    await set(newThreadRef, newThread);
    return threadId;
  }

  public async updateChatThread(threadId: string, updates: Partial<ChatThread>): Promise<void> {
    const threadRef = ref(realtimeDb, `threads/${threadId}`);
    // Firebase Realtime Database set doesn't support merge option
    // Get current data first, then update
    const snapshot = await get(threadRef);
    const currentData = snapshot.exists() ? snapshot.val() : {};
    await set(threadRef, { ...currentData, ...updates });
  }

  public async getChatThreads(metadata?: ChatThread['metadata']): Promise<ChatThread[]> {
    const threadsRef = ref(realtimeDb, 'threads');
    const snapshot = await get(threadsRef);
    const threads: ChatThread[] = [];

    snapshot.forEach((childSnapshot) => {
      const thread = childSnapshot.val() as ChatThread;
      if (!metadata || this.matchesMetadata(thread.metadata, metadata)) {
        threads.push(thread);
      }
    });

    return threads.sort((a, b) => b.updatedAt - a.updatedAt);
  }

  public subscribeToUserThreads(userId: string, callback: (threads: ChatThread[]) => void): () => void {
    console.log('RealtimeService: Setting up thread subscription for user:', userId);
    
    if (!userId) {
      console.error('RealtimeService: No userId provided for thread subscription');
      callback([]);
      return () => {};
    }

    // Unsubscribe from any existing listener for this user
    if (this.userThreadListeners.has(userId)) {
      console.log('RealtimeService: Cleaning up existing thread subscription');
      this.userThreadListeners.get(userId)!();
    }

    const threadsRef = ref(realtimeDb, 'threads');
    const unsubscribe = onValue(threadsRef, (snapshot) => {
      console.log('RealtimeService: Received threads snapshot, exists:', snapshot.exists());
      
      try {
        if (snapshot.exists()) {
          const threads = Object.entries(snapshot.val())
            .map(([id, data]: [string, any]) => {
              if (!data) return null;

              // Ensure all required fields are present
              const thread: ChatThread = {
                id,
                title: data.title || 'Untitled',
                participants: Array.isArray(data.participants) ? data.participants : [],
                createdAt: Number(data.createdAt) || Date.now(),
                updatedAt: Number(data.updatedAt) || Date.now(),
                messages: Array.isArray(data.messages) ? data.messages : [],
                lastMessage: data.lastMessage || null,
                metadata: data.metadata || {}
              };

              // Only include threads that have this user as a participant
              return thread.participants.includes(userId) ? thread : null;
            })
            .filter((thread): thread is ChatThread => thread !== null);

          // Sort threads by updatedAt timestamp, newest first
          threads.sort((a, b) => b.updatedAt - a.updatedAt);
          
          console.log('RealtimeService: User threads found:', threads.length);
          callback(threads);
        } else {
          console.log('RealtimeService: No threads found');
          callback([]);
        }
      } catch (error) {
        console.error('RealtimeService: Error processing threads:', error);
        callback([]);
      }
    });

    // Store the unsubscribe function
    this.userThreadListeners.set(userId, unsubscribe);

    return unsubscribe;
  }

  private matchesMetadata(threadMetadata?: ChatThread['metadata'], queryMetadata?: ChatThread['metadata']): boolean {
    if (!threadMetadata || !queryMetadata) return false;
    
    // Special handling for type field to match collaboration_application
    if (queryMetadata.type === 'collaboration_application' && threadMetadata.type !== 'collaboration_application') {
      return false;
    }
    
    return Object.entries(queryMetadata).every(([key, value]) => {
      if (key === 'type') return true; // Already handled above
      return threadMetadata[key] === value;
    });
  }

  public subscribeToChatThread(threadId: string, callback: (thread: ChatThread) => void): () => void {
    console.log('RealtimeService: Setting up chat thread subscription for:', threadId);
    
    // Unsubscribe from any existing listener for this thread
    if (this.threadListeners.has(threadId)) {
      this.threadListeners.get(threadId)!();
    }

    const threadRef = ref(realtimeDb, `threads/${threadId}`);
    const messagesRef = ref(realtimeDb, `messages/${threadId}`);
    let currentThread: ChatThread | null = null;

    let messageListener: (() => void) | null = null;
    const currentUserId = this.getCurrentUserId();

    // Subscribe to thread updates
    const unsubscribeThread = onValue(threadRef, async (snapshot) => {
      if (!snapshot.exists()) {
        console.error('Thread not found:', threadId);
        return;
      }

      try {
        const data = snapshot.val();
        currentThread = {
          id: threadId,
          title: data.title || 'Untitled',
          participants: Array.isArray(data.participants) ? data.participants : [],
          messages: [], // Will be populated from messages collection
          createdAt: Number(data.createdAt) || Date.now(),
          updatedAt: Number(data.updatedAt) || Date.now(),
          lastMessage: data.lastMessage || null,
          metadata: data.metadata || {}
        };

        // Get only the last 50 messages initially
        const messagesSnapshot = await get(query(messagesRef, orderByChild('timestamp'), limitToLast(50)));
        if (messagesSnapshot.exists() && currentThread) {
          const messages: Message[] = [];
          messagesSnapshot.forEach((childSnapshot) => {
            messages.push(childSnapshot.val() as Message);
          });

          // Sort messages by timestamp
          messages.sort((a, b) => a.timestamp - b.timestamp);
          currentThread.messages = messages;

          // Update lastMessage if needed
          if (messages.length > 0) {
            currentThread.lastMessage = messages[messages.length - 1];
          }
        }

        // Clean up existing message listener if any
        if (messageListener) {
          messageListener();
        }

        // Subscribe to all message changes
        messageListener = onValue(messagesRef, (messagesSnapshot) => {
          if (currentThread && messagesSnapshot.exists()) {
            const messages: Message[] = [];
            messagesSnapshot.forEach((childSnapshot) => {
              messages.push(childSnapshot.val() as Message);
            });
            messages.sort((a, b) => a.timestamp - b.timestamp);

            // Check for new messages that need notifications
            // Commented out unused variable
            // const lastKnownMessageId = currentThread.messages?.[currentThread.messages?.length - 1]?.id;
            const newMessages = messages.filter(msg => {
              // Find messages that weren't in our previous list
              const isNew = currentThread ? !currentThread.messages?.some(existing => existing.id === msg.id) : true;
              return isNew;
            });

            // Send notifications for new messages
            if (currentUserId && currentThread && newMessages.length > 0) {
              this.sendChatNotifications(currentThread, newMessages, currentUserId);
            }

            currentThread.messages = messages;
            currentThread.lastMessage = messages[messages.length - 1];
            
            console.log('RealtimeService: Thread messages updated:', {
              id: currentThread.id,
              messageCount: messages.length
            });

            callback(currentThread);
          }
        });

        console.log('RealtimeService: Thread data updated:', {
          id: currentThread.id,
          participantCount: currentThread.participants.length
        });
      } catch (error) {
        console.error('RealtimeService: Error processing thread data:', error);
        // Create a minimal valid thread to prevent errors
        currentThread = {
          id: threadId,
          title: 'Error Loading Thread',
          participants: [],
          messages: [],
          createdAt: Date.now(),
          updatedAt: Date.now(),
          metadata: { type: 'error' }
        };
        callback(currentThread);
      }
    });

    this.threadListeners.set(threadId, unsubscribeThread);
    return unsubscribeThread;
  }

  public async sendMessageToThread(threadId: string, message: Partial<Message>): Promise<void> {
    console.log('RealtimeService: Sending message to thread:', threadId);
    
    // Validate message content
    if (!message.content || message.content.trim().length === 0) {
      throw new Error('Message content cannot be empty');
    }
    if (!message.sender || !message.sender.id) {
      throw new Error('Message must have a valid sender');
    }

    const threadsRef = ref(realtimeDb, `threads/${threadId}`);
    const messagesRef = ref(realtimeDb, `messages/${threadId}`);
    
    try {
      // First get the current thread data
      const threadSnapshot = await get(threadsRef);
      if (!threadSnapshot.exists()) {
        throw new Error('Thread not found');
      }

      // Get thread data and ensure required fields exist
      const data = threadSnapshot.val();
      const thread: ChatThread = {
        id: threadId,
        title: data.title || 'Untitled',
        participants: Array.isArray(data.participants) ? data.participants : [],
        messages: [],
        createdAt: Number(data.createdAt) || Date.now(),
        updatedAt: Number(data.updatedAt) || Date.now(),
        metadata: data.metadata || {},
        lastMessage: data.lastMessage || null
      };

      // Log thread data for debugging
      console.log('RealtimeService: Thread data:', {
        id: thread.id,
        participantCount: thread.participants.length,
        sender: message.sender.id
      });

      // Validate sender unless it's a system message
      if (message.sender.id !== 'system' && !thread.participants.includes(message.sender.id)) {
        throw new Error(`Sender ${message.sender.id} is not a participant in thread ${threadId}. Participants: ${thread.participants.join(', ')}`);
      }

      // Get sender's profile information
      const senderProfile = await this.getUserProfile(message.sender.id);

      // Create the new message
      const fullMessage: Message = {
        id: `${threadId}_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
        content: message.content.trim(),
        sender: {
          id: message.sender.id,
          name: senderProfile.name,
          avatar: senderProfile.avatar
        },
        timestamp: Date.now(),
        read: false,
        type: message.type || 'text'
      };

      // Store the message in the messages collection
      const newMessageRef = push(messagesRef);
      await set(newMessageRef, fullMessage);

      // Update the thread's lastMessage and updatedAt
      const threadUpdates = {
        lastMessage: fullMessage,
        updatedAt: fullMessage.timestamp
      };

      await set(threadsRef, { ...thread, ...threadUpdates });

      console.log('RealtimeService: Added message to thread:', {
        threadId,
        messageId: fullMessage.id,
        messageType: fullMessage.type
      });
    } catch (error) {
      console.error('RealtimeService: Error sending message:', error);
      throw error;
    }
  }

  public async markMessagesAsRead(threadId: string, userId: string): Promise<void> {
    console.log('RealtimeService: Marking messages as read in thread:', threadId);
    const threadsRef = ref(realtimeDb, `threads/${threadId}`);
    const messagesRef = ref(realtimeDb, `messages/${threadId}`);
    
    try {
      // Get the current thread data
      const threadSnapshot = await get(threadsRef);
      if (!threadSnapshot.exists()) {
        throw new Error('Thread not found');
      }
      const thread = threadSnapshot.val() as ChatThread;

      // Validate that user is a participant
      if (!thread.participants.includes(userId)) {
        throw new Error('User is not a participant in this thread');
      }

      // Get all messages
      const messagesSnapshot = await get(messagesRef);
      if (!messagesSnapshot.exists()) {
        return; // No messages to update
      }

      // Update read status for all unread messages not sent by the user
      let hasUpdates = false;
      const updates: { [key: string]: any } = {};

      messagesSnapshot.forEach((messageSnapshot) => {
        const message = messageSnapshot.val() as Message;
        if (message.sender.id !== userId && !message.read) {
          hasUpdates = true;
          updates[`${messageSnapshot.key}/read`] = true;
        }
      });

      if (hasUpdates) {
        // Update individual message read status
        for (const [messageKey, value] of Object.entries(updates)) {
          await set(ref(realtimeDb, `messages/${threadId}/${messageKey}`), value);
        }

        // Update the thread's last message read status if needed
        const threadData = (await get(threadsRef)).val();
        if (threadData.lastMessage && !threadData.lastMessage.read && threadData.lastMessage.sender.id !== userId) {
          await set(ref(realtimeDb, `threads/${threadId}/lastMessage/read`), true);
        }

        console.log('RealtimeService: Messages marked as read successfully');
      } else {
        console.log('RealtimeService: No unread messages to update in thread:', threadId);
      }
    } catch (error) {
      console.error('RealtimeService: Error marking messages as read:', error);
      throw error;
    }
  }

  // Chat Rooms
  public async getChatRooms(userId: string): Promise<ChatRoom[]> {
    const roomsRef = ref(realtimeDb, 'chatRooms');
    const snapshot = await get(roomsRef);
    const rooms: ChatRoom[] = [];

    snapshot.forEach((childSnapshot) => {
      const room = childSnapshot.val() as ChatRoom;
      if (room.participants.includes(userId)) {
        rooms.push({ ...room, id: childSnapshot.key! });
      }
    });

    return rooms.sort((a, b) => b.updatedAt - a.updatedAt);
  }

  public getChatRoomId(user1Id: string, user2Id: string): string {
    return [user1Id, user2Id].sort().join('_');
  }

  public cleanup(): void {
    this.messageListeners.forEach((unsubscribe) => unsubscribe());
    this.presenceListeners.forEach((unsubscribe) => unsubscribe());
    this.messageListeners.clear();
    this.presenceListeners.clear();
  }
}

export default RealtimeService;
