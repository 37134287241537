import { memo } from "react";
import { Github, Twitter, Linkedin, Mail, Rocket } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "./common/Button";

const socialLinks = [
  {
    name: "GitHub",
    url: "https://github.com/launchpadng",
    icon: <Github className="w-5 h-5" />,
  },
  {
    name: "Twitter",
    url: "https://twitter.com/launchpadng",
    icon: <Twitter className="w-5 h-5" />,
  },
  {
    name: "LinkedIn",
    url: "https://linkedin.com/company/launchpadng",
    icon: <Linkedin className="w-5 h-5" />,
  },
  {
    name: "Email",
    url: "mailto:hello@launchpad.ng",
    icon: <Mail className="w-5 h-5" />,
  },
];

const footerLinks = [
  {
    title: "Community",
    links: [
      { name: "Directory", href: "/directory" },
      { name: "Events", href: "/events" },
      { name: "Projects", href: "/projects" },
      { name: "Collaboration", href: "/collaboration" },
    ],
  },
  {
    title: "Resources",
    links: [
      { name: "Blog", href: "/blog" },
      { name: "Documentation", href: "/docs" },
      { name: "Support", href: "/support" },
      { name: "Terms", href: "/terms" },
    ],
  },
  {
    title: "Company",
    links: [
      { name: "About", href: "/about" },
      { name: "Careers", href: "/careers" },
      { name: "Contact", href: "/contact" },
      { name: "Privacy", href: "/privacy" },
    ],
  },
];

const SocialLink = memo(({ link }: { link: typeof socialLinks[0] }) => (
  <a
    href={link.url}
    target="_blank"
    rel="noopener noreferrer"
    className="text-muted-foreground hover:text-primary transition-colors"
    aria-label={link.name}
  >
    {link.icon}
  </a>
));

SocialLink.displayName = 'SocialLink';

const FooterLinkSection = memo(({ section }: { section: typeof footerLinks[0] }) => (
  <div className="space-y-3">
    <h3 className="text-sm font-semibold tracking-wider">
      {section.title}
    </h3>
    <ul className="space-y-2">
      {section.links.map((link) => (
        <li key={link.name}>
          <Link
            to={link.href}
            className="text-sm text-muted-foreground hover:text-primary transition-colors"
          >
            {link.name}
          </Link>
        </li>
      ))}
    </ul>
  </div>
));

FooterLinkSection.displayName = 'FooterLinkSection';

const NewsletterSection = memo(() => (
  <div className="space-y-4">
    <h3 className="text-lg font-semibold">Stay Updated</h3>
    <p className="text-sm text-muted-foreground">
      Get notified about the latest features and updates. No spam.
    </p>
    <form className="flex gap-2 max-w-md" onSubmit={(e) => e.preventDefault()}>
      <input
        type="email"
        placeholder="Enter your email"
        className="flex-1 h-10 px-3 bg-background rounded-md border text-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary"
      />
      <Button type="submit" size="sm">Subscribe</Button>
    </form>
  </div>
));

NewsletterSection.displayName = 'NewsletterSection';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="mt-auto border-t bg-card w-full">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12 lg:py-16 space-y-6 md:space-y-8">
        <div className="grid gap-6 sm:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
          {/* Brand Section */}
          <div className="space-y-4">
            <Link to="/" className="inline-flex items-center space-x-2 hover:opacity-90 transition-opacity">
              <Rocket className="w-5 h-5 text-primary" />
              <span className="text-xl font-semibold">Launchpad</span>
            </Link>
            <p className="text-sm text-muted-foreground">
              Connect with Nigeria's brightest builders, founders, and tech innovators.
              Join the community shaping the future of technology.
            </p>
            <div className="flex gap-4">
              {socialLinks.map((link) => (
                <SocialLink key={link.name} link={link} />
              ))}
            </div>
          </div>

          {/* Links Sections */}
          {footerLinks.map((section) => (
            <FooterLinkSection key={section.title} section={section} />
          ))}
        </div>

        {/* Newsletter Section */}
        <div className="pt-8 border-t">
          <NewsletterSection />
        </div>

        {/* Bottom Section */}
        <div className="pt-6 md:pt-8 border-t flex flex-col md:flex-row justify-between gap-4 text-xs sm:text-sm text-muted-foreground">
          <p> {currentYear} Launchpad. All rights reserved.</p>
          <div className="flex gap-6">
            <Link to="/terms" className="hover:text-primary transition-colors">Terms</Link>
            <Link to="/privacy" className="hover:text-primary transition-colors">Privacy</Link>
            <Link to="/cookies" className="hover:text-primary transition-colors">Cookies</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default memo(Footer);